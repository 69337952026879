import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"

import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import StoreIcon from "@material-ui/icons/Store"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import LockIcon from '@material-ui/icons/Lock'

import '../../../styles/SidePanel.css'

const mapStateToProps = state => {
    return {
        app: state.app,
    }
}

const Message = React.memo(props => {


    const handleSignOut = () => {
        localStorage.removeItem("ChatKeepLog:")
        window.location.reload()
    }

    const handleStoreSelection = () => {
        localStorage.removeItem("CountrChat:SelectedStore")
        props.history.push("/store")
        props.setEmployee({})
    }




    return (
        <div>
            <DialogTitle>
                <span style={{ color: "#ffffff" }} className="countr-fonts">Menu</span>
            </DialogTitle>
            <DialogContent>
                <Grid container>

                    <Grid item xs={12}>
                        <Button onClick={handleSignOut}>
                            <ExitToAppIcon
                                style={{ marginRight: "1vw", color: "#ffffff6b" }}
                            />
                            <span style={{ color: "#ffffff" }} className="countr-fonts">
                                Select employee
                            </span>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={props.handleChangeAvatarModal}>
                            <AddAPhotoIcon
                                style={{ marginRight: "1vw", color: "#ffffff6b" }}
                            />
                            <span style={{ color: "#ffffff" }} className="countr-fonts">
                                Change avatar image
                            </span>
                        </Button>
                    </Grid>


                    <Grid item xs={12}>
                        {props.app.employeeSelected.rights === "admin" && (
                            <Button onClick={handleStoreSelection}>
                                <StoreIcon
                                    style={{ marginRight: "1vw", color: "#ffffff6b" }}
                                />
                                <span style={{ color: "#ffffff" }} className="countr-fonts">
                                    Change store
                                </span>
                            </Button>
                        )}
                    </Grid>

                    {props.app.user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID && (
                        <Grid container>
                            <Grid item xs={12} style={{ marginTop: '2vh' }}>
                                <Button onClick={() => props.handleAddCountrUserModal('NEW')}>
                                    <PersonAddIcon
                                        style={{ marginRight: "1vw", color: "#ffffff6b" }}
                                    />
                                    <span style={{ color: "#ffffff" }} className="countr-fonts">
                                        Add new Countr Employee
                                </span>
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Button onClick={() => props.handleAddCountrUserModal('UPDATE')}>
                                    <LockIcon
                                        style={{ marginRight: "1vw", color: "#ffffff6b" }}
                                    />
                                    <span style={{ color: "#ffffff" }} className="countr-fonts">
                                        Change your password
                                </span>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    className="new-group-btns"
                    color="secondary"
                    onClick={props.handleOpenChatMenu}
                    style={{ fontWeight: "800" }}>
                    close
              </Button>
            </DialogActions>

        </div>
    )
})

export default withRouter(
    connect(mapStateToProps, null)(Message)
)
