import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles"

import CountrResources from '../../../utils/CountrResources'
import { setParticipants, setRoomLoading, setChannelTracker } from "../../../store/actions/chatRoom"

import Logo from "../../../assets/countr_white.png"
import PanelMenu from '../../Main/SidePanel/PanelMenu'
import ChangeAvatarModal from "../../Main/SidePanel/ChangeAvatarModal"
import NewGroupChat from '../../Main/SidePanel/NewGroupChat'
import CountrAddUser from './CountrAddUser'
import CountrSearchListModal from '../CountrComponents/CountrSearchListModal'

import Brightness1Icon from "@material-ui/icons/Brightness1"
import StoreIcon from '@material-ui/icons/Store'
import GroupIcon from "@material-ui/icons/Group"
import EmailIcon from "@material-ui/icons/Email"
import ControlPointIcon from "@material-ui/icons/ControlPoint"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import MenuIcon from "@material-ui/icons/Menu"

import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Popover from "@material-ui/core/Popover"
import Button from "@material-ui/core/Button"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import Badge from "@material-ui/core/Badge"
import Collapse from "@material-ui/core/Collapse"


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store,
        chatRoom: state.chatRoom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        setRoomLoading: status => dispatch(setRoomLoading(status)),
        setChannelTracker: channel => dispatch(setChannelTracker(channel))
    }
}


const CountrSidePanel = React.memo(props => {
    const classes = useStyles()
    const [viewWidth, setViewWidth] = useState(0)
    const [popOvertarget, setPopOverTarget] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [mobileView, setMobileView] = useState(false)
    const [employeeOnUse, setEmployeeOnUse] = useState({})
    const [openAvatarModal, setOpenAvatarModal] = useState(false)
    const [openChatMenu, setOpenChatMenu] = useState(false)
    const [openNewGroupChat, setOpenNewGroupChat] = useState(false)
    const [openCountrUserModal, setOpenCountrUserModal] = useState(false)
    const [countrModalMode, setCountrModalMode] = useState('')
    const [storeVisible, setStoreVisible] = useState([])
    const [privatseVisible, setPrivateVisible] = useState([])
    const [openSearchStoreModal, setOpenSearchStoreModal] = useState(false)
    const [searchModalList, setSearchModalList] = useState([])


    const handleOpenChatMenu = (target) => {
        if (!openChatMenu) {
            setOpenChatMenu(true)
            setPopOverTarget(target)
        } else setOpenChatMenu(false)
    }

    const handleChangeAvatarModal = () => {
        if (openAvatarModal) {
            setOpenAvatarModal(false)
        } else setOpenAvatarModal(true)
    }

    const handleSearchSupportChannelModal = () => {
        if (openSearchStoreModal) {
            setOpenSearchStoreModal(false)
        } else setOpenSearchStoreModal(true)
    }


    const handleNewGroupChat = () => {
        if (!openNewGroupChat) {
            setOpenNewGroupChat(true)
        } else {
            setOpenNewGroupChat(false)
        }
    }

    const handleAddCountrUserModal = (mode) => {
        setCountrModalMode(mode)
        if (openCountrUserModal) {
            setOpenCountrUserModal(false)
        } else setOpenCountrUserModal(true)
    }

    const handleIsActive = (id) => {
        let active = { color: "#ffffff" }
        if (props.chatRoom._id && props.chatRoom._id === id) {
            active = { color: "#00FF00" }
        }

        return active
    }

    const handleIsOnline = (ids) => {

        let participantId = ids.filter(
            (id) => id !== props.app.employeeSelected._id
        )
        let employee = props.store.allEmployees.find(
            (emp) => emp._id === participantId[0]
        )
        if (employee && employee.online) {
            return { fontSize: "12px", marginRight: "15px", color: "#44b700" }
        } else return { fontSize: "12px", marginRight: "15px", color: "red" }
    }

    const handleRoomTitle = (title) => {
        let newTitle = ""
        let thisTitle = title.split("-")
        thisTitle.forEach((name) => {
            if (name.trim() !== props.app.employeeSelected.first_name.trim()) {
                newTitle = name
            }
        })
        return newTitle
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleUnreadMessages = (room) => {
        let unread = null
        room.tracker.forEach((id) => {
            if (id.participant_id === props.app.employeeSelected._id) {
                let channelTracker = props.chatRoom.channelTracker.find(id => id.id === room._id)
                if (channelTracker) {
                    unread = channelTracker.messages_no - Number(id.message_no)
                }
            }
        })
        return unread
    }

    const handleUnreadSupportMessages = (room) => {
        let unread = room.unread ? `new` : null
        if (room.tracker && room.tracker.countr) {
            return unread = `new`
        }
        return unread
    }

    const handleSupportCategoryUnread = rooms => {
        let newUnread = rooms.filter(room => room.unread === true)
        let unread = rooms.filter(room => room.tracker.countr === true)
        return unread.length + newUnread.length
    }

    const handleCategoryUnread = (rooms) => {
        let unread = null
        if (rooms.length > 0) {
            rooms.forEach((room) => {
                let formatedRoom = {}
                if (room.room) {
                    formatedRoom = room.room
                } else formatedRoom = room

                formatedRoom.tracker.forEach((id) => {
                    if (id.participant_id === props.app.employeeSelected._id) {
                        let channelTracker = props.chatRoom.channelTracker.find(id => id.id === room._id)
                        if (channelTracker) {
                            unread = channelTracker.messages_no - id.message_no + unread
                        }
                    }
                })
            })
        }
        return unread
    }

    const handleChatChange = async (channel, isAdmin) => {
        if (viewWidth < 650) {
            setTimeout(() => {
                setMobileView(mobileView ? false : true)
            }, 500)
        }
        if (!channel.type) {
            props.setRoomLoading(true)
            let room = await CountrResources.getChatRoom(channel._id, isAdmin)
            props.setParticipants(room)
            if (room) {
                props.setRoomLoading(false)
            }
        } else {
            props.setRoomLoading(true)
            let room = await CountrResources.getSupportChatRoomCountr(channel._id)
            props.setParticipants(room)

            if (room) {
                props.setRoomLoading(false)
            }

        }
    }

    const handleSearchStoreModal = (list) => {
        setSearchModalList(list)
        setOpenSearchStoreModal(true)
    }

    useEffect(() => {
        let visible = props.privateSupport.filter(room => room.tracker && (room.tracker.countr || room.unread))
        setPrivateVisible(visible)
    }, [props.privateSupport])

    useEffect(() => {
        let visible = props.storeSupport.filter(room => room.tracker && (room.tracker.countr || room.unread))
        setStoreVisible(visible)
    }, [props.storeSupport])

    useEffect(() => {
        const vw = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )
        setViewWidth(vw)
        if (vw < 650) {
            setMobileView(true)
        }

        setEmployeeOnUse(props.app.employeeSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="side-panel">
            {mobileView ? (
                <Collapse in={mobileView} timeout={2000}>
                    <Drawer
                        className="mobile-view-button"
                        variant="persistent"
                        anchor="left"
                        open={mobileView}>
                        <MenuIcon
                            style={{ fontSize: 40 }}
                            button="true"
                            onClick={() => setMobileView(mobileView ? false : true)}
                        />
                    </Drawer>
                </Collapse>
            ) : (
                    <div className={classes.root}>
                        <CssBaseline />
                        <Collapse in={!mobileView}>
                            <Drawer
                                className={classes.drawer}
                                variant="persistent"
                                anchor="left"
                                open={!mobileView}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}>
                                {viewWidth < 650 ? (
                                    <img
                                        className="main-menu-logo-login"
                                        src={Logo}
                                        alt="logo"
                                        button={mobileView ? "true" : "false"}
                                        onClick={() => setMobileView(mobileView ? false : true)}
                                    />
                                ) : (
                                        <img className="main-menu-logo-login" src={Logo} alt="logo" />
                                    )}
                                <div
                                    className={classes.drawerHeader}
                                    style={{ marginRight: "5vw", marginTop: "2vh" }}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span>
                                                <IconButton
                                                    onClick={(event) =>
                                                        handleOpenChatMenu(event.currentTarget)
                                                    }
                                                >
                                                    <Avatar
                                                        className="avatar-user"
                                                        variant="square"
                                                        style={{ backgroundColor: '#e8fbe8' }}
                                                        src={props.app.employeeSelected.contactRawSchema ?
                                                            props.app.employeeSelected.contactRawSchema.image :
                                                            `https://avatars.dicebear.com/v2/jdenticon/${props.app.employeeSelected.first_name}${props.app.employeeSelected.last_name}.svg`
                                                        }
                                                    />
                                                </IconButton>
                                            </span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="chat-owner-name">{employeeOnUse.name}</div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Accordion
                                    style={{ marginTop: "5vh" }}
                                    className="panel-main-menu"
                                    expanded={expanded === "panel3"}
                                    onChange={handleChange("panel3")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <StoreIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Badge
                                                    color="error"
                                                    badgeContent={handleSupportCategoryUnread(props.storeSupport)}
                                                >
                                                    <Typography className="countr-fonts">Store Support</Typography>
                                                </Badge>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Button
                                                    className="new-group-btn"
                                                    variant="outlined"
                                                    onClick={() => handleSearchStoreModal(props.storeSupport)}>
                                                    Search Store Channels
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List>
                                                    {storeVisible.map((room) => (
                                                        <ListItem
                                                            style={handleIsActive(room._id)}
                                                            className="admin-rooms-list"
                                                            button
                                                            key={room._id}
                                                            onClick={() => handleChatChange(room)}
                                                        >
                                                            <Badge
                                                                color="error"
                                                                badgeContent={handleUnreadSupportMessages(room)}
                                                            >
                                                                <ListItemText
                                                                    className="chat-room-lsit countr-sub-fonts"
                                                                    primary={`@ ${room.title.split("-")[0]}`}
                                                                />
                                                            </Badge>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Divider />

                                <Accordion
                                    className="panel-main-menu"
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <AccountCircleIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Badge
                                                    color="error"
                                                    badgeContent={handleSupportCategoryUnread(props.privateSupport)}
                                                >
                                                    <Typography className="countr-fonts">Private Support</Typography>
                                                </Badge>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Button
                                                    className="new-group-btn"
                                                    variant="outlined"
                                                    onClick={() => handleSearchStoreModal(props.privateSupport)}>
                                                    Search Private Channels
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List>
                                                    {privatseVisible.map((room) => (
                                                        <ListItem
                                                            style={handleIsActive(room._id)}
                                                            className="admin-rooms-list"
                                                            button
                                                            key={room._id}
                                                            onClick={() => handleChatChange(room)}
                                                        >
                                                            <Badge
                                                                color="error"
                                                                badgeContent={handleUnreadSupportMessages(room)}
                                                            >
                                                                <ListItemText
                                                                    className="chat-room-lsit countr-sub-fonts"
                                                                    primary={`@ ${room.title}`}
                                                                />
                                                            </Badge>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Divider />


                                <Accordion
                                    className="panel-main-menu"
                                    expanded={expanded === "panel1"}
                                    onChange={handleChange("panel1")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <GroupIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Badge
                                                    color="error"
                                                    badgeContent={handleCategoryUnread(props.groupChannels)}
                                                >
                                                    <Typography className="countr-fonts">Group Chats</Typography>
                                                </Badge>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Button
                                                    className="new-group-btn"
                                                    variant="outlined"
                                                    onClick={handleNewGroupChat}
                                                >
                                                    <Grid container style={{ marginTop: "0.5vh", marginRight: '7px' }}>
                                                        <Grid item xs={11} className="countr-fonts">
                                                            New group chat
                                                    </Grid>
                                                        <Grid item xs={1}>
                                                            <ControlPointIcon fontSize="small" />
                                                        </Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List>
                                                    {props.groupChannels.map((room) => (
                                                        <ListItem
                                                            className="countr-sub-fonts-group"
                                                            style={handleIsActive(room._id)}
                                                            value={room._id}
                                                            key={room._id}
                                                            button={true}
                                                            onClick={() => handleChatChange(room)}
                                                        >
                                                            <Badge
                                                                color="error"
                                                                badgeContent={handleUnreadMessages(room)}
                                                            >
                                                                <ListItemText># {room.title}</ListItemText>
                                                            </Badge>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Divider />

                                <Accordion
                                    className="panel-main-menu"
                                    expanded={expanded === "panel2"}
                                    onChange={handleChange("panel2")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <EmailIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Badge
                                                    color="error"
                                                    badgeContent={handleCategoryUnread(props.privateChannels)}
                                                >
                                                    <Typography className="countr-fonts">Private Messages</Typography>
                                                </Badge>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {props.privateChannels && props.privateChannels.map((room) => (
                                                <ListItem
                                                    className="countr-sub-fonts"
                                                    style={handleIsActive(room._id)}
                                                    button
                                                    key={room._id}
                                                    onClick={() => handleChatChange(room)}
                                                >
                                                    <Brightness1Icon
                                                        style={handleIsOnline(room.participants)}
                                                    />
                                                    <Badge
                                                        color="error"
                                                        badgeContent={handleUnreadMessages(room)}
                                                    >
                                                        <ListItemText>
                                                            {handleRoomTitle(room.title)}
                                                        </ListItemText>
                                                    </Badge>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>

                            </Drawer>
                        </Collapse>
                        <Popover
                            className="chat-in-menu"
                            anchorEl={popOvertarget}
                            open={openChatMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}>
                            <PanelMenu
                                handleOpenChatMenu={handleOpenChatMenu}
                                handleChangeAvatarModal={handleChangeAvatarModal}
                                handleAddCountrUserModal={handleAddCountrUserModal}
                            />
                        </Popover>
                    </div>
                )}
            {openNewGroupChat && (
                <NewGroupChat
                    open={openNewGroupChat}
                    handleClose={handleNewGroupChat}
                />
            )}
            {openAvatarModal && (
                <ChangeAvatarModal
                    open={openAvatarModal}
                    handleClose={handleChangeAvatarModal}
                />
            )}
            {openCountrUserModal && (
                <CountrAddUser
                    open={openCountrUserModal}
                    countrModalMode={countrModalMode}
                    countrMerchant={props.app.user._id}
                    handleClose={handleAddCountrUserModal}
                />
            )}
            {openSearchStoreModal && (
                <CountrSearchListModal
                    open={openSearchStoreModal}
                    handleClose={handleSearchSupportChannelModal}
                    searchModalList={searchModalList}
                    handleChatChange={handleChatChange}
                />
            )}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CountrSidePanel)


const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#1d212757",
            outline: "1px solid slategrey",
        },
    },

    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "#000",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#1d2127",
        color: "#fff",
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        "&::-webkit-scrollbar": {
            width: "0px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))
