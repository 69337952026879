import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'

import '../../../styles/Messages.css'

const mapStateToProps = state => {
    return {
        app: state.app,
        chatRoom: state.chatRoom,
        store: state.store,
        sockets: state.sockets
    }
}


const ReadBy = React.memo(props => {
    const [seenMessageAvatar, setSeenMessageAvatar] = useState([])


    const handleAvatarReadMessages = room => {
        let last = room.messages.length
        let list = []
        room.tracker.forEach(id => {
            if (id.message_no === last &&
                id.participant_id !== props.app.employeeSelected._id
            ) {
                let seenFrom = props.store.allEmployees.find(emp => emp._id === id.participant_id && (emp.first_name + ' ' + emp.last_name) !== room.messages[last - 1].sender)
                if (seenFrom) {
                    let avatarFormat = {
                        empId: seenFrom._id,
                        first: seenFrom.first_name,
                        last: seenFrom.last_name
                    }
                    list.push(avatarFormat)
                }
            }
        })
        // let checkedList = list.filter(id => id.empId !== props.app.employeeSelected._id)
        setSeenMessageAvatar(list)
    }

    const handleTootip = () => {
        if (seenMessageAvatar) {
            return (
                <List>
                    {seenMessageAvatar.map(name => (
                        <ListItem style={{ fontWeight: '800', fontSize: '10px', fontFamily: 'Balsamiq Sans' }} key={name.empId}>{name.first} {name.last}</ListItem>
                    ))}

                </List>
            )
        }
    }

    useEffect(() => {
        if (props.chatRoom._id) {
            setSeenMessageAvatar([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formatedMessages])



    useEffect(() => {
        if (props.chatRoom._id &&
            props.sockets.updatedTracker._id &&
            props.sockets.updatedTracker.tracker.length > 1) {
            handleAvatarReadMessages(props.sockets.updatedTracker)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.updatedTracker])

    return (
        <div>
            {seenMessageAvatar.length !== 0 && props.formatedMessages.length !== 0 && (
                <Grid container>
                    <Grid item xs={11}>
                        <p></p>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip
                            arrow
                            className="read-by-tooltip"
                            title={handleTootip()}
                            multiline='true'
                        >
                            <CheckCircleIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
            {props.formatedMessages.length !== 0 && seenMessageAvatar.length === 0 && (
                <Grid container>
                    <Grid item xs={11}>
                        <p></p>
                    </Grid>
                    <Grid item xs={1}>
                        <CheckCircleIcon />
                    </Grid>
                </Grid>
            )}
        </div>
    )
})

export default connect(mapStateToProps)(ReadBy)
