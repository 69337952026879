import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Text } from "react-internationalization"

import Logo from "../../assets/countr_white.png"

import { setLoading, showToast } from "../../store/actions/app"
import { setStoreId, setStore } from "../../store/actions/store"

import CountrResources from "../../utils/CountrResources"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"


import "../../styles/Login.css"

const mapStateToProps = (state) => {
  return {
    app: state.app,
    store: state.store,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (state) => dispatch(setLoading(state)),
    showToast: (msg) => dispatch(showToast(msg)),
    setStoreId: (id) => dispatch(setStoreId(id)),
    setStore: (store) => dispatch(setStore(store)),
  }
}

function StoreSelection(props) {
  const [stores, setStore] = useState([])
  const [selectedStore, setSelectedStore] = useState("")

  let resource = null

  const requestApi = async () => {
    props.setLoading(true)

    let store = await resource.getResource("stores").catch((e) => {
      const msg = e.message ? e.message : JSON.stringify(e)
      props.showToast(JSON.stringify(msg))

      setTimeout(() => {
        localStorage.setItem("rememberPassword", JSON.stringify(false))
        props.history.push("/")
        props.setLoading(false)
      }, 0)
    })

    props.setLoading(false)
    setStore(store)
  }

  const handleLocalStore = async (localStore) => {
    resource = new CountrResources()
    let allStores = await resource.getResource("stores")

    let store = allStores.find((store) => store._id === localStore)
    if (store) {
      props.setStoreId(store)
      props.history.push("/employee")
    }
  }

  // Using async/await in useEffect throw some warnings
  useEffect(() => {
    const localStore = localStorage.getItem("CountrChat:SelectedStore")

    if (props.app.isDasUser) {
      props.history.push('/dashboard')
    } else if (localStore && localStore !== 'dashboard') {
      handleLocalStore(localStore)
    } else {
      resource = new CountrResources()
      requestApi()
    }
  }, [])

  const handleChangeStore = (event) => {
    setSelectedStore(event.target.value)
  }

  const getSelectedStore = () => {
    return stores.find((store) => {
      if (store._id === selectedStore) {
        delete store.devices
      }

      return store
    })
  }

  const handleClickProceed = () => {
    if (selectedStore.length) {
      props.setStore(getSelectedStore())
      props.setStoreId(selectedStore)
      localStorage.setItem("CountrChat:SelectedStore", selectedStore)
      props.history.push("/employee")
    }
  }

  return (
    <Grid id="app-login" container className="flex-center" style={{ backgroundColor: '#D3D3D3', height: '100vh' }}>
      <Paper elevation={20} className="login-form-paper">
        <Grid item xs={12}>
          <div className="logo-div">
            <Typography>
              <img className="main-header-logo-login" src={Logo} alt="logo" />
            </Typography>
            <div className="main-header-logo-text-login">Chat</div>
          </div>
        </Grid>

        <Grid item xs={12} className="flex-center" style={{ marginTop: "2vh" }}>
          <h1 className="login-store-info" style={{ fontSize: "1.6em" }}>
            Select a store to connect
          </h1>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "3vh", marginBottom: "3vh" }}>
          {stores && (
            <FormControl className="login-input-store">
              <Select
                variant="outlined"
                value={selectedStore}
                onChange={handleChangeStore}
                inputProps={{
                  name: "store",
                  id: "select-store",
                }}
              >
                {stores.map((store) => (
                  <MenuItem key={store._id} value={store._id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12} className="flex-center">
          <div className="login-store-info">
            The device will be connected with the selected store <br />
            <Divider className="login-divider-store" />
            An admin can change the connected store <br />
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "1vh" }}>
          <Button
            variant="contained"
            color="secondary"
            className={
              !selectedStore.length ? "login-button-disabled" : "login-button"
            }
            onClick={handleClickProceed}
            disabled={!selectedStore.length ? true : false}
          >
            {props.app.isLoading ? (
              <CircularProgress className="btn-loader" />
            ) : (
                <Text>select</Text>
              )}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreSelection)
)
