import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { setLoading } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'

const mapStateToProps = state => {
    return {
        store: state.store,
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: status => dispatch(setLoading(status))
    }
}


const ChatInfoModal = React.memo(props => {
    const [roomCreator, setRoomCretor] = useState({})
    const [roomState, setRoomState] = useState({
        title: '',
        users: [],
        messages_no: 0,
        rights: ''
    })

    const handleChatRoom = () => {
        let emps = props.store.allEmployees
        let users = []

        props.room.participants.forEach(prt => {
            let user = emps.find(emp => emp._id === prt)
            if (user && user._id !== props.room.creator[0]) {
                let insert = {
                    image: user.contactRawSchema,
                    id: user._id,
                    name: `${user.first_name} ${user.last_name}`,
                    first: user.first_name,
                    last: user.last_name,
                    rights: user.rights
                }
                users.push(insert)
            }
        })

        setRoomState({
            title: props.room.title,
            users: users,
            messages_no: props.room.messages.length,
            rights: props.room.viewers
        })
        if (props.room.creator.length === 1) {
            let creator = emps.find(emp => emp._id === props.room.creator[0])
            setRoomCretor({
                image: creator.contactRawSchema,
                id: creator._id,
                name: `${creator.first_name} ${creator.last_name}`,
                first: creator.first_name,
                last: creator.last_name,
                rights: creator.rights

            })

        }
    }

    const handleUrerAvatar = (emp) => {
        if (emp.image) {
            return emp.image.image
        } else return `https://avatars.dicebear.com/v2/jdenticon/${emp.first}${emp.last}.svg`
    }


    useEffect(() => {
        handleChatRoom()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="info-room-header">
                <span className="info-title"><u>#{props.room.title}</u></span> <span className="info-title-info">channel info</span></DialogTitle>
            <DialogContent>
                <List>
                    <Grid container>
                        {props.room.creator.length === 1 && (
                            <Grid item xs={12}>
                                <h4><Chip
                                    className="new-date-chip"
                                    label={'Creator'}
                                /></h4>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            className="message-avatar"
                                            variant="square"
                                            style={{ backgroundColor: '#fbe8fb' }}
                                            src={handleUrerAvatar(roomCreator)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{ marginLeft: '10px' }}
                                        primary={roomCreator.name}
                                        secondary={roomCreator.rights}
                                    />

                                </ListItem>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <h4><Chip
                                className="new-date-chip"
                                label={'Users'}
                            /></h4>
                        </Grid>
                        {roomState.users.map(user => (
                            <Grid item xs={12} key={user.id}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            className="message-avatar"
                                            variant="square"
                                            style={{ backgroundColor: '#fbe8fb' }}
                                            src={handleUrerAvatar(user)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{ marginLeft: '10px' }}
                                        primary={user.name}
                                        secondary={user.rights}
                                    />
                                </ListItem>
                            </Grid>

                        ))}
                    </Grid>

                </List>
            </DialogContent>

            <DialogActions style={{ marginTop: '2vh' }}>
                <Button className="new-group-btns" variant="contained" color="secondary" onClick={() => props.onClose()}>close</Button>
            </DialogActions>
        </Dialog>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatInfoModal)
