import CountrClient from "@countr/sdk"

/**
 * Creating a instance for Countr SDK
 */
export const AppInstances = (function () {
  var instance

  async function createCountrSdk() {
    const countrClient = new CountrClient({
      opts: {
        // staging:
        //   process.env.REACT_APP_PROD === "false" &&
        //     process.env.REACT_APP_STAGING === "true"
        //     ? true
        //     : false,
        // local:
        //   process.env.REACT_APP_PROD === "false" &&
        //     process.env.REACT_APP_STAGING === "false"
        //     ? true
        //     : false,
        enableSocket: true,
        staging: false,
        local: false,
        production: true
      },
    })

    countrClient.setClient({
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })

    countrClient.on("refreshed", async (token) => {
      try {
        localStorage.setItem("access_token", token.access_token)
        localStorage.setItem("refresh_token", token.refresh_token)
      } catch (error) {
        console.log(error)
      }
    })

    return countrClient
  }

  return {
    getCountrSdk: function () {
      if (!instance) {
        // console.log('****** CREATING NEW INSTANCE FOR SDK ******')
        instance = createCountrSdk()
      }

      // Checking if is not connected, than force it to connect again
      instance.then((countr) => {

        if (!countr.ws.socket.connected) {
          countr.ws.socket.connect()
        }
      })

      return instance
    },

    /**
     * Log error with Countr
     */
    logError: async (errorObj) => {
      const countr = await instance

      errorObj.date = new Date().toISOString()
      errorObj.source = process.env.REACT_APP_ERROR_SOURCE

      try {
        countr.e.create(errorObj).catch((error) => {
          console.log(error)
        })
      } catch (error) {
        console.log(error)
      }
    },
  }
})()
