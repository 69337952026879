import React from 'react'
import { connect } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import { dismissToast } from './../store/actions/app'

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dismissToast: () => dispatch(dismissToast())
  }
}

function ToastMessage(props) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={props.app.showToast}
      onClose={props.dismissToast}
      message={<span id="message-id">{props.app.toastMsg}</span>}
      action={
        <Button color="secondary" size="small" onClick={props.dismissToast}>
          Close
        </Button>
      }
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastMessage)
