import {
    SET_INCOMING_MSG,
    SET_IS_TYPING,
    TRACKER_UPDATE,
    NEW_CHANNEL,
    UPDATE_CHANNEL,
    DELETED_CHANNEL,
    ONLINE_UPDATE,
    NEW_SUPPORT_CHANNEL,
    CLOSE_CONVERSATION,
    EMPLOYEE_LOGOUT
} from '../actions/actionTypes'

const initialState = {
    incomingMessage: {},
    channelTyping: [],
    updatedTracker: [],
    newChannel: {},
    updatedChannel: {},
    deletedChannel: {},
    onlineUpdate: '',
    newSupportChannel: {},
    closedConvresation: {},
    employeeLogout: ''
}

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INCOMING_MSG:
            return {
                ...state,
                incomingMessage: action.payload
            }
        case SET_IS_TYPING:
            return {
                ...state,
                channelTyping: action.payload
            }
        case TRACKER_UPDATE:
            return {
                ...state,
                updatedTracker: action.payload
            }
        case NEW_CHANNEL:
            return {
                ...state,
                newChannel: action.payload
            }
        case UPDATE_CHANNEL:
            return {
                ...state,
                updatedChannel: action.payload
            }
        case DELETED_CHANNEL:
            return {
                ...state,
                deletedChannel: action.payload
            }
        case ONLINE_UPDATE:
            return {
                ...state,
                onlineUpdate: action.payload
            }
        case NEW_SUPPORT_CHANNEL:
            return {
                ...state,
                newSupportChannel: action.payload
            }
        case CLOSE_CONVERSATION:
            return {
                ...state,
                closedConvresation: action.payload
            }
        case EMPLOYEE_LOGOUT:
            return {
                ...state,
                employeeLogout: action.payload
            }
        default:
            return state
    }
}

export default socketReducer
