import React, { useState, useEffect } from "react"

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import '../../../styles/SidePanel.css'

function CountrSearchListModal(props) {
    const [searchResult, setSearchResult] = useState([])

    const handListSearch = value => {
        let includedValue = props.searchModalList.filter(room => room.title.includes(value))
        setSearchResult(includedValue)
    }

    const handelSelected = room => {
        props.handleChatChange(room)
        props.handleClose()
    }

    useEffect(() => {
        setSearchResult(props.searchModalList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="info-room-header">
                    {props.searchModalList.length > 0 ? (
                        <p className="support-cannels-list"> Search {props.searchModalList[0].type} channel list</p>
                    ) : (
                            <p className="support-cannels-list">No channels</p>
                        )}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="input-with-icon-textfield"
                        onChange={(e) => handListSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <List>
                        {searchResult.map(room => (
                            <ListItem
                                className="admin-rooms-list"
                                button
                                key={room._id}
                                onClick={() => handelSelected(room)}
                            >
                                <ListItemText
                                    className="chat-room-lsit countr-sub-fonts"
                                    primary={`@ ${room.title.split("-")[0]}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>


                <DialogActions style={{ marginTop: '2vh' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleClose}>
                        Close
                    </Button>
                </DialogActions>

            </Dialog>

        </div>
    )
}

export default CountrSearchListModal
