import React, { useState } from 'react'
import { connect } from 'react-redux'

import { setParticipants } from '../../../store/actions/chatRoom'
import { showToast } from '../../../store/actions/app'

import PersonIcon from '@material-ui/icons/Person'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import ChatInfoModal from './ChatInfoModal'
import '../../../styles/Messages.css'


const mapStateToProps = state => {
    return {
        app: state.app,
        chatRoom: state.chatRoom,
        store: state.store
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        showToast: msg => dispatch(showToast(msg))
    }
}


const MessageRoomHeader = React.memo(props => {
    const [chatInfo, setChatInfo] = useState(false)


    const handleOpenChatInfo = () => {
        if (!chatInfo) {
            setChatInfo(true)
        } else {
            setChatInfo(false)
        }
    }


    return (
        <div>
            <Paper className="message-room-header">
                <Grid container>
                    {props.chatRoom.participants && props.chatRoom.participants.length === 2 && (
                        <Grid item xs={12} className="message-room-user-name">
                            <span>{props.chatRoom.title}</span><br />
                        </Grid>
                    )}
                    {props.chatRoom.participants && props.chatRoom.participants.length > 2 && (
                        <Grid item xs={12} className="message-room-user-name">
                            <Grid item xs={12}># <span>{props.chatRoom.title}</span><br /></Grid>
                            <Grid item xs={12}>
                                <PersonIcon style={{ fontSize: '28px', color: "#C0C0C0" }} />
                                <span className="group-users-txt">
                                    {props.chatRoom.participants.length}
                                    <span style={{ fontWeight: 'normal', marginLeft: '0.5rem', color: "#C0C0C079" }}>|</span>
                                    <Button
                                        style={{ color: '#C0C0C0' }}
                                        onClick={handleOpenChatInfo}
                                    >chat info <InfoOutlinedIcon className="chat-info-btn" />
                                    </Button>
                                    <span style={{ fontWeight: 'normal', marginLeft: '0.5rem', color: "#C0C0C079" }}>|</span>
                                </span>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            {chatInfo && (
                <ChatInfoModal
                    open={chatInfo}
                    onClose={handleOpenChatInfo}
                    room={props.chatRoom}
                />
            )}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageRoomHeader)
