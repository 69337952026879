import {
    SET_CHAT_WITH,
    SET_SEND_MSG,
    ROOM_LOADING,
    CHANNEL_TRACKER,
    PRIVATE_SUPPORT_CHANNEL,
    STORE_SUPPORT_CHANNEL
} from '../actions/actionTypes'

const initialState = {
    _id: "",
    title: "",
    participants: [],
    viewers: [],
    created_at: "",
    updated_at: "",
    messages: [],
    merchant: '',
    type: "",
    store: "",
    newSendMessage: {},
    roomLoading: false,
    channelTracker: [],
    privateSupportChannel: {},
    storeSupportChannel: {}
}

const chatRoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHAT_WITH:
            return {
                ...state,
                _id: action.payload._id,
                title: action.payload.title,
                creator: action.payload.creator,
                participants: action.payload.participants,
                viewers: action.payload.viewers,
                created_at: action.payload.created_at,
                updated_at: action.payload.updated_ataction,
                messages: action.payload.messages,
                tracker: action.payload.tracker,
                type: action.payload.type,
                store: action.payload.store,
                merchant: action.payload.merchant
            }
        case SET_SEND_MSG:
            return {
                ...state,
                newSendMessage: action.payload
            }
        case ROOM_LOADING:
            return {
                ...state,
                roomLoading: action.payload
            }
        case CHANNEL_TRACKER:
            return {
                ...state,
                channelTracker: action.payload
            }
        case PRIVATE_SUPPORT_CHANNEL:
            return {
                ...state,
                privateSupportChannel: action.payload
            }
        case STORE_SUPPORT_CHANNEL:
            return {
                ...state,
                storeSupportChannel: action.payload
            }
        default:
            return state
    }
}

export default chatRoomReducer
