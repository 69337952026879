// App actions
export const SET_USER = 'SET_USER'
export const SET_LOADING = 'SET_LOADING'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const SHOW_TOAST = 'SHOW_TOAST'
export const DISMISS_TOAST = 'DISMISS_TOAST'
export const SET_EMBEDED_DEVICE = 'SET_EMBEDED_DEVICE'
export const IS_IFRAME = 'IS_IFRAME'
export const IS_DASHBOARD_USER = 'IS_DASHBOARD_USER'

// STORE actions
export const SET_STORE_ID = 'SET_STORE_ID'
export const SET_STORE = 'SET_STORE'
export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_ADMINS = 'SET_ADMINS'
export const SET_ALL_EMPLOYEES = 'SET_ALL_EMPLOYEES'

// Device Actions
export const SET_DEVICE = 'SET_DEVICE'
export const SET_DEVICE_SETTINGS = 'SET_DEVICE_SETTINGS'

//ChatRoom Actions
export const SET_CHAT_WITH = 'SET_CHAT_WITH'
export const SET_SEND_MSG = 'SET_SEND_MSG'
export const ROOM_LOADING = 'ROOM_LOADING'
export const CHANNEL_TRACKER = 'CHANNEL_TRACKER'
export const PRIVATE_SUPPORT_CHANNEL = 'PRIVATE_SUPPORT_CHANNEL'
export const STORE_SUPPORT_CHANNEL = 'STORE_SUPPORT_CHANNEL'

//Socket Actions
export const SET_INCOMING_MSG = 'SET_INCOMING_MSG'
export const TRACKER_UPDATE = 'TRACKER_UPDATE'
export const SET_IS_TYPING = 'SET_IS_TYPING'
export const NEW_CHANNEL = 'NEW_CHANNEL'
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL'
export const DELETED_CHANNEL = 'DELETED_CHANNEL'
export const ONLINE_UPDATE = 'ONLINE_UPDATE'
export const NEW_SUPPORT_CHANNEL = 'NEW_SUPPORT_CHANNEL'
export const CLOSE_CONVERSATION = 'CLOSE_CONVERSATION'
export const EMPLOYEE_LOGOUT = 'EMPLOYEE_LOGOUT'
