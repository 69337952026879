import { AppInstances } from './CountrSdk'

// const headers = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json'
// }

export default class CountrResources {
  login = async (username, password) => {
    // Getting countr sdk instace
    const countr = await AppInstances.getCountrSdk()


    // after registered, it will set all tokens
    countr.once('registered', token => {
      localStorage.setItem('token_type', token.token_type)
      localStorage.setItem('access_token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)

      countr.setToken({
        access_token: token.access_token,
        refresh_token: token.refresh_token
      })
    })

    // Performing login
    return countr.register(username, password).then(
      user => {
        localStorage.setItem('user', JSON.stringify(user))
        return Promise.resolve(user)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  autoLogin = async () => {
    const countr = await AppInstances.getCountrSdk()
    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')

    countr.setToken({
      access_token: access_token,
      refresh_token: refresh_token
    })

    return countr.me.read().then(
      user => {
        localStorage.setItem('user', JSON.stringify(user))
        return Promise.resolve(user)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  getResource = async (
    resource,
    limit = process.env.REACT_APP_RESOURCE_LIMIT,
    sort = '-updated_at'
  ) => {
    const countr = await AppInstances.getCountrSdk()

    return countr[resource].read({ limit, sort }).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  getResourceDetails = async (resource, id) => {
    const countr = await AppInstances.getCountrSdk()
    return countr[resource].readOne(id).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  updateResource = async (resource, item) => {
    const countr = await AppInstances.getCountrSdk()
    return countr[resource].update(item._id, item).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  checkKDSExtenstion = async () => {
    // Checking if this account has KDS extension
    const countr = await AppInstances.getCountrSdk()
    const extensions = await countr.me.extensions.read()

    return extensions
  }

  static getStores = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    // ${process.env.REACT_APP_API_SERVER}/
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/stores/detailed`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getEmployees = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    // ${process.env.REACT_APP_API_SERVER}/
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }


  static postNewChatRoom = async (room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        title: room.title,
        merchant: room.merchant,
        // store: store,
        creator: room.creator,
        participants: room.participants,
        viewers: room.viewers,
        tracker: room.tracker
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static patchChatRoom = async (room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel/${room._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        ...room,
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static patchChatRoomCountr = async (room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport/${room._id}/countr`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        tracker: room.tracker,
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }


  static updateTracker = async (room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel/tracker/${room.chatchannel}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        chatchannel: room.chatchannel,
        tracker: room,
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }

  }


  static updateOnline = async (emp, socket) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees/${emp._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        online: true,
        socket_id: socket
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }

  }

  static updateAvatar = async (emp, img) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees/${emp._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        contactRawSchema: {
          image: img,
        }
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }

  }


  // static updateTyping = async (room) => {
  //   const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel/typing/${room.chatchannel}`, {
  //     method: 'PATCH',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${localStorage.getItem('access_token')}`
  //     },
  //     body: JSON.stringify({
  //       chatchannel: room.chatchannel,
  //       typing: room,
  //     })
  //   })

  //   const content = await rawResponse.json()

  //   if (content && !content.error) {
  //     return await content
  //   } else {
  //     // @TODO LOG TO ERROR LOG
  //     console.log(content)
  //   }

  // }



  static getAllUsersChatRooms = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }


  static getChatRoom = async (id) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel/${id}`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getSupportChatRoom = async (id) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport/${id}`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getSupportChatRoomCountr = async (id) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport/${id}/countr`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer `
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }



  static postMessage = async (msg, room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        merchant: msg.merchant,
        sequence_no: msg.sequence_no,
        sender: msg.sender,
        date: msg.date,
        content: msg.content,
        chatchannel: room._id
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static postSupportMessage = async (msg, room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/supportmessages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        merchant: msg.merchant,
        sequence_no: msg.sequence_no,
        sender: msg.sender,
        date: msg.date,
        content: msg.content,
        supportChannel: room._id
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static postCountrSupportMessage = async (msg, room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/supportmessages/countr`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        merchant: msg.merchant,
        sequence_no: msg.sequence_no,
        sender: msg.sender,
        date: msg.date,
        content: msg.content,
        supportChannel: room._id
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }



  static deleteChatRoom = async id => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/chatchannel/${id}`, {
      method: 'DELETE',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getMessages = async (room) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/messages?limit=${room.limit}&skip=${room.skip}&channel=${room._id}`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getSupportMessages = async (room) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/supportmessages?limit=${room.limit}&skip=${room.skip}&channel=${room._id}`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }


  static postNewCountrUser = async (user) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        ...user
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getEmployeesCount = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees/count`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static updateUsersPin = async (newPin, id) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/employees/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        pin: newPin,
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }

  }

  static getUsersCountrSupport = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static postNewCountrSupportChannel = async (room) => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      body: JSON.stringify({
        title: room.title,
        merchant: room.merchant,
        store: room.store,
        creator: room.creator,
        type: room.type,
        tracker: room.tracker,
        countr: room.countr
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static getAllSupportChannels = async () => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    const rawResponse = await fetch(`${process.env.REACT_APP_API_SERVER}/countrsupport/countr`, {
      method: 'GET',
      headers: Object.assign(headers, {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      })
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

}


