import {
  SET_LOADING,
  SHOW_TOAST,
  DISMISS_TOAST,
  SET_USER,
  SET_EMPLOYEE,
  SET_EMBEDED_DEVICE,
  IS_IFRAME,
  IS_DASHBOARD_USER
} from './actionTypes'

export const setUser = user => ({
  type: SET_USER,
  payload: user
})

export const isIframe = iframe => ({
  type: IS_IFRAME,
  payload: iframe
})

export const isDashUser = dash => ({
  type: IS_DASHBOARD_USER,
  payload: dash
})

export const setEmbededDevice = deviceId => ({
  type: SET_EMBEDED_DEVICE,
  payload: deviceId
})

export const setLoading = status => ({
  type: SET_LOADING,
  payload: status
})

export const showToast = msg => ({
  type: SHOW_TOAST,
  payload: msg
})

export const dismissToast = () => ({
  type: DISMISS_TOAST
})

export const setEmployee = employee => ({
  type: SET_EMPLOYEE,
  payload: employee
})
