import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { AppInstances } from '../../utils/CountrSdk'
import CountrResources from '../../utils/CountrResources'

import Logo from '../../assets/countr_white.png'

import { setLoading, setEmployee, showToast } from '../../store/actions/app'
import { setStore, setEmployees, setStoreId, setAdmins } from '../../store/actions/store'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import '../../styles/Login.css'


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: status => dispatch(setLoading(status)),
        setStore: store => dispatch(setStore(store)),
        setEmployees: employees => dispatch(setEmployees(employees)),
        setStoreId: storeId => dispatch(setStoreId(storeId)),
        setEmployee: employee => dispatch(setEmployee(employee)),
        setAdmins: admins => dispatch(setAdmins(admins)),
        showToast: msg => dispatch(showToast(msg))
    }
}


const EmployeeSelection = React.memo(props => {
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [keepLoggin, setKeepLogin] = useState(false)

    const handleChangePassword = event => {
        setPassword(event.target.value)
    }

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handlePasswordCheck(event)
        }
    }

    const handlePasswordVisible = () => {
        setShowPassword(!showPassword)
    }

    const handlePasswordCheck = async (event) => {
        if (selectedEmployee && selectedEmployee.pin) {
            if (password === selectedEmployee.pin) {
                props.setEmployee(selectedEmployee)
                if (props.app.user._id !== process.env.REACT_APP_COUNTR_SUPPORT_ID) {
                    props.history.push('/main')
                } else props.history.push('/countr')

                handleRegisterOnline(selectedEmployee)
            } else {
                event.preventDefault()
                props.showToast('wrong PIN')
            }
        }
    }

    const handleSetKeepLogin = () => {
        if (!keepLoggin) {
            setKeepLogin(true)
            localStorage.setItem('ChatKeepLog:', selectedEmployee._id)
        } else {
            setKeepLogin(false)
            localStorage.removeItem('ChatKeepLog:')
        }

    }

    const handleSelectEmployee = event => {
        const selected = props.store.employees.find(employee => employee.name === event.target.value)
        if (selected.rights !== 'user' && selected.rights !== 'none') {
            setKeepLogin(false)
        }
        setSelectedEmployee(selected)
    }

    const handleEmployeeChatLogg = async id => {
        const logged = props.store.employees.find(employee => employee._id === id)
        props.setEmployee(logged)
        if (props.app.user._id !== process.env.REACT_APP_COUNTR_SUPPORT_ID) {
            props.history.push('/main')
        } else props.history.push('/countr')

        handleRegisterOnline(logged)
    }

    const handleRegisterOnline = async selectedEmployee => {
        const countr = await AppInstances.getCountrSdk()

        let online = await CountrResources.updateOnline(selectedEmployee, countr.ws.socket.id)
        if (online.error) {
            props.showToast(online.error)
        }
    }

    useEffect(() => {
        let keepLogged = localStorage.getItem('ChatKeepLog:')
        let reloadUser = localStorage.getItem(`reload_user`)
        if (props.app.isDasUser){
            props.history.push('/dashboard')
        }else if (keepLogged && keepLogged !== null) {
            localStorage.removeItem(`reload_user`)
            handleEmployeeChatLogg(keepLogged)
        } else if (reloadUser) {
            localStorage.removeItem(`reload_user`)
            props.showToast(`Error on channel load. Reload succeeded`)
            handleEmployeeChatLogg(reloadUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEmployees = async () => {
        let list = []
        const employees = await CountrResources.getEmployees()
        const selectedStore = localStorage.getItem('CountrChat:SelectedStore')

        if (selectedStore) {
            try {
                let resource = new CountrResources()
                const stores = await resource.getResource("stores")
                if (stores && stores.length) {

                    let store = stores.find(store => store._id === selectedStore)

                    props.setStore(store)

                    props.setStoreId(store._id)

                    store.employees.forEach(employee => {
                        let selected = employees.find(emp => emp._id === employee)
                        list.push(selected)
                    })
                    props.setEmployees(list)

                } else {
                    props.history.push('/store')
                }
            } catch (error) {
                props.history.push('/store')
            }
        }

        props.setLoading(false)
    }

    useEffect(() => {
        handleEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Grid id='app-login' container className='flex-center' style={{backgroundColor: '#D3D3D3', height: '100vh'}}>
            <Paper elevation={20} className="login-form-paper">
                <Grid item xs={12}>
                    <div className="logo-div">
                        <Typography>
                            <img className="main-header-logo-login" src={Logo} alt="logo" />
                        </Typography>
                        <div className="main-header-logo-text-login">Chat</div>
                    </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '3vh' }}>
                    <FormControl className="login-input">
                        <InputLabel id="demo-simple-select-label">Select Employee</InputLabel>
                        <Select
                            label='select employee'
                            id="demo-simple-select"
                            value={selectedEmployee.name ? selectedEmployee.name : ""}
                            onChange={handleSelectEmployee}
                        >
                            {props.store.employees.map(employee => (
                                <MenuItem value={employee.name} key={employee._id}>
                                    {employee.name}
                                </MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '2vh' }}>
                    <form>
                        <TextField
                            autoComplete="true"
                            label="Password"
                            className="login-input"
                            variant="outlined"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={event => handleChangePassword(event)}
                            onKeyPress={event => handleEnterPress(event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='Toggle password visibility'
                                            onClick={() => handlePasswordVisible()}
                                        >
                                            {showPassword ? (
                                                <Icon><Visibility /></Icon>
                                            ) : (
                                                    <Icon><VisibilityOff /></Icon>
                                                )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}

                        />
                    </form>

                </Grid>
                <Grid item xs={12} className='login-checkbox-employee'>
                    <FormGroup row className='login-form-control-row'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    disabled={selectedEmployee.rights === "user" ||
                                        selectedEmployee.rights === "none" ? false : true}
                                    checked={keepLoggin}
                                    onChange={handleSetKeepLogin}
                                />
                            }
                            label='Keep me logged'
                        />
                    </FormGroup>
                    <div className="logout-info">* You can logout later from the chat menu</div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '1vh' }}>
                    <Button
                        label="Login"
                        className="login-button"
                        variant="contained"
                        onClick={handlePasswordCheck}
                    >
                        ok
                    </Button>
                </Grid>



            </Paper>
        </Grid>
    )
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeSelection))
