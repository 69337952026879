import {
  SET_CHAT_WITH,
  SET_SEND_MSG,
  ROOM_LOADING,
  CHANNEL_TRACKER,
  PRIVATE_SUPPORT_CHANNEL,
  STORE_SUPPORT_CHANNEL
} from './actionTypes'

export const setParticipants = user => ({
  type: SET_CHAT_WITH,
  payload: user
})

export const setNewSendMessage = msg => ({
  type: SET_SEND_MSG,
  payload: msg
})


export const setRoomLoading = loading => ({
  type: ROOM_LOADING,
  payload: loading
})

export const setChannelTracker = channel => ({
  type: CHANNEL_TRACKER,
  payload: channel
})

export const setPrivateSupportChannel = cnl => ({
  type: PRIVATE_SUPPORT_CHANNEL,
  payload: cnl
})

export const setStoreSupportChannel = cnl => ({
  type: STORE_SUPPORT_CHANNEL,
  payload: cnl
})