import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import VisibilitySensor from 'react-visibility-sensor'

import CountrResources from '../../../utils/CountrResources'
import { showToast } from '../../../store/actions/app'
import { setNewSendMessage } from '../../../store/actions/chatRoom'
import { setNewIncomingMessage } from '../../../store/actions/sockets'

import Logo from '../../../assets/countr_white.png'
import ReadBy from './ReadBy'
// import Typing from './Typing'

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'

import '../../../styles/Messages.css'

const mapStateToProps = state => {
    return {
        app: state.app,
        chatRoom: state.chatRoom,
        store: state.store,
        sockets: state.sockets
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setNewSendMessage: user => dispatch(setNewSendMessage(user)),
        setNewIncomingMessage: msg => dispatch(setNewIncomingMessage(msg)),
        showToast: msg => dispatch(showToast(msg))
    }
}

const Message = React.memo(props => {
    const [roomMessages, setRoomMessages] = useState([])
    const [formatedMessages, setFormatedMessages] = useState([])
    const [roomAvatar, setRoomAvatar] = useState({})
    const [skipCounter, setSkipCounter] = useState(40)
    const [roomTotalMessages, setRoomToatlMessages] = useState(0)
    const [prevLoaded, setPrevLoaded] = useState([])
    const [firstLoad, setFirstLoad] = useState(true)
    const [noMessages, setNoMessages] = useState(false)
    const [incomingMessages, setIncomingMessages] = useState([])


    const handleMessages = messages => {
        let list = []
        let isNewDate = ""
        let user = props.app.employeeSelected._id
        let sorted = messages.sort((a, b) => new Date(a.date) - new Date(b.date))
        let checkDoubles = [...new Set(sorted)]
        let avatarUser = false
        let messageTime = ''
        let msgId = ''
        let allEmps = props.store.allEmployees
        if (props.app.user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID) {
            allEmps.push(props.app.employeeSelected)
        }

        checkDoubles.forEach(message => {
            if (message.sender && (message.chatchannel === props.chatRoom._id || message.supportChannel === props.chatRoom._id)) {

                if (message.sender === props.app.employeeSelected._id) {
                    message = {
                        ...message,
                        myMessage: true,
                    }
                }

                let name = allEmps.find(employee => employee._id === message.sender)
                if (name || message.sender.includes("-") || message.sender.includes("Countr Support")) {
                    let newDate = moment(message.date).format("DD-MM-YYYY")
                    let msgTime = moment(message.date).format("DD-MM-YYYY hh:mm")
                    let userMessageNo = props.chatRoom.tracker.length && props.chatRoom.tracker.find(id => id.participant_id === props.app.employeeSelected._id)

                    if (userMessageNo &&
                        message.sequence_no.toString() === userMessageNo.message_no.toString() &&
                        userMessageNo.message_no.toString() !== props.chatRoom.messages.length.toString()) {
                        let incoming = incomingMessages.find(msg => msg._id === message._id)
                        if (!incoming) {
                            message = {
                                ...message,
                                newMessage: true
                            }
                        }
                    }

                    if (messageTime.length === 0 || messageTime !== msgTime) {
                        msgId = message.sender
                        messageTime = msgTime
                    } else if (messageTime === msgTime && msgId === message.sender) {
                        message = {
                            ...message,
                            sameTime: true
                        }
                    }

                    if (newDate !== isNewDate) {
                        isNewDate = newDate

                        message = {
                            ...message,
                            newDay: newDate
                        }
                    }

                    if (message.sender === user && !props.app.employeeSelected.contactRawSchema) {
                        avatarUser = true
                    } else avatarUser = false

                    let first = name ? name.first_name : message.sender
                    let last = name ? name.last_name : ""

                    let time = moment(message.date).format("hh:mm a")
                    let senderName = name ? `${name.first_name} ${name.last_name}` : message.sender
                    message.sender = senderName


                    message = {
                        ...message,
                        time: time,
                        avatar: `${first}${last}`,
                        user: avatarUser,
                        image: name ? name.contactRawSchema : ``
                    }

                    list.push(message)
                }
            }
        })
        if (formatedMessages.length > 0 &&
            list.length === 1 &&
            (props.chatRoom._id === formatedMessages[0].chatchannel || props.chatRoom._id === formatedMessages[0].supportChannel)) {
            let msgTime = moment(list[0].date).format("DD-MM-YYYY hh:mm")
            let lastOne = formatedMessages.length - 1
            let lastOneDate = formatedMessages[lastOne].date
            let lastOneId = formatedMessages[lastOne].sender
            let msgDate = moment(lastOneDate).format("DD-MM-YYYY")
            let nwDate = moment(new Date()).format("DD-MM-YYYY")
            let msgId = lastOneId
            let messageTime = moment(lastOneDate).format("DD-MM-YYYY hh:mm")

            if (messageTime === msgTime && msgId === list[0].sender) {
                list[0] = {
                    ...list[0],
                    sameTime: true
                }
            }

            if (msgDate === nwDate)
                list[0] = {
                    ...list[0],
                    sendedMessage: true
                }
            let existed = formatedMessages.find(msg => msg._id === list[0]._id)
            if (!existed) {
                let msgs = [...formatedMessages, list[0]]
                setFormatedMessages(msgs)
            }
        } else setFormatedMessages(list)
    }

    const handleAvatarColour = (message) => {
        if (message.myMessage) {
            return { backgroundColor: '#e8fbe8' }
        } else return { backgroundColor: '#fbe8fb' }
    }

    const handleNewMessage = async () => {
        if (props.chatRoom.newSendMessage && props.chatRoom.newSendMessage._id) {
            setSkipCounter(skipCounter + 1)
            setIncomingMessages([...incomingMessages, props.chatRoom.newSendMessage])
            handleMessages([props.chatRoom.newSendMessage])
            props.setNewSendMessage({})
        }
        if (props.sockets.incomingMessage &&
            props.sockets.incomingMessage._id) {
            if (props.chatRoom._id === (props.sockets.incomingMessage.chatchannel || props.sockets.incomingMessage.supportChannel)) {
                setSkipCounter(skipCounter + 1)
                setIncomingMessages([...incomingMessages, props.sockets.incomingMessage])

                handleMessages([props.sockets.incomingMessage])
            }
            props.setNewIncomingMessage({})
        }
    }

    const handleMessageSender = (message) => {
        if (message && message.chatchannel) {
            return (
                <span style={{ fontSize: '18px' }}>{message.sender} <span className="message-date">{message.time}</span></span>
            )
        } else if (message && message.supportChannel && props.app.user._id) {
            return (
                <span style={{ fontSize: '18px' }}>{message.sender.split("-")[0]} <span className="message-date">{message.time}</span></span>
            )
        }
    }

    const handleMessageContent = message => {
        if (message) {
            return (
                <span className="message-content">{message}</span>
            )
        }
    }

    const handleMessageLoad = async () => {
        if (skipCounter < roomTotalMessages) {
            setSkipCounter(skipCounter + 40)
            let room = {
                _id: props.chatRoom._id,
                skip: skipCounter,
                limit: 40
            }
            let loadedMessages = await CountrResources.getMessages(room)
            handleMessagesLoad(loadedMessages)

        } else if (skipCounter > roomTotalMessages && skipCounter - roomTotalMessages >= 40) {
            let room = {
                _id: props.chatRoom._id,
                skip: skipCounter - roomTotalMessages,
                limit: skipCounter - roomTotalMessages
            }
            let loadedMessages = await CountrResources.getMessages(room)
            handleMessagesLoad(loadedMessages)

        } else {
            setNoMessages(true)
            props.showToast('No more messages')
        }

    }
    const handleMessagesLoad = loaded => {
        let sumLoaded = [...loaded, ...prevLoaded]
        setPrevLoaded(sumLoaded)
        let messages = [...sumLoaded, ...roomMessages, ...incomingMessages]
        setRoomMessages(messages)
    }

    const handlePagination = () => {
        if (props.chatRoom._id.length > 0 && !props.chatRoom.type) {
            let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
            if (roomTracker) {
                setRoomToatlMessages(roomTracker.messages_no)
            } else {
                localStorage.setItem(`reload_user`, props.app.employeeSelected._id)
                window.location.reload()
            }
        }
    }


    //======> Scroll to bottom 
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView()
        }
    }

    //Remove deps
    useEffect(scrollToBottom, [formatedMessages]);
    //<=============================


    useEffect(() => {
        setFormatedMessages([])
        setRoomMessages(props.chatRoom.messages)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatRoom._id])

    useEffect(() => {
        handlePagination()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatRoom.channelTracker])

    useEffect(() => {
        //TODO 
        handleMessages(roomMessages)
        if (props.chatRoom.participants && props.chatRoom.participants.length === 2) {
            let participant = props.chatRoom.participants.filter(id => id !== props.app.employeeSelected._id)
            let employee = props.store.allEmployees.find(emp => emp._id === participant[0])
            if (employee && employee.contactRawSchema) {
                setRoomAvatar({
                    name: employee.first_name + '' + employee.last_name,
                    image: employee.contactRawSchema.image
                })
            } else if (employee) {
                setRoomAvatar({
                    name: employee.first_name + '' + employee.last_name,
                    avatar: employee.first_name + employee.last_name
                })
            }
        } else if (props.chatRoom.participants && props.chatRoom.participants.length > 2) {
            let creator = [...props.store.allEmployees, props.app.employeeSelected].find(emp => emp._id === props.chatRoom.creator[0])

            setRoomAvatar({
                title: props.chatRoom.title,
                creator: creator.first_name + '' + creator.last_name,
                date: props.chatRoom.created_at,
                image: creator.contactRawSchema ? creator.contactRawSchema.image : null,
                avatar: !creator.contactRawSchema ? creator.first_name + creator.last_name : creator.contactRawSchema.image
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomMessages])


    useEffect(() => {
        handleNewMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatRoom.newSendMessage, props.sockets.incomingMessage])

    //============Request for more messages is visible ===========>
    const onChange = (isVisible) => {
        setFirstLoad(false)
        if (isVisible && !firstLoad) {
            handleMessageLoad()
        }
    }
    //<=============================================================
    return (
        <div>
            <List className="messages-list" >
                {formatedMessages.length > 39 && !noMessages && (
                    <VisibilitySensor onChange={onChange}>
                        <div>...Loading...</div>
                    </VisibilitySensor>
                )}

                {formatedMessages.length > 0 && formatedMessages.map((message, index) => (
                    <Grid key={message._id} container>
                        {index === 40 && (
                            <div ref={messagesEndRef} className="auto-focus-div" />
                        )}

                        <Grid item xs={12}>
                            {message.newDay && !message.sendedMessage && (
                                <h4><Chip
                                    className="new-date-chip"
                                    label={message.newDay}
                                /></h4>
                            )}
                            {message.newMessage && !message.newDay && (
                                <h3><Chip
                                    className="new-message-chip"
                                    label='New'
                                /></h3>
                            )}
                            <ListItem className="signgle-message">
                                <ListItemAvatar style={{ marginRight: '2rem' }}>
                                    {message.sender === "Countr Support" ? (
                                        <Avatar
                                            className="message-avatar"
                                            variant="square"
                                            style={handleAvatarColour(message)}
                                            //Uploaded counter image 
                                            src={`http://res.cloudinary.com/countr-prod/image/upload/v1594283630/v2znmboshxoresdu8rxf.png`}
                                        />
                                    ) : (
                                            <Avatar
                                                className="message-avatar"
                                                variant="square"
                                                style={handleAvatarColour(message)}
                                                src={message.image ? message.image.image : `https://avatars.dicebear.com/v2/jdenticon/${message.avatar}.svg`}
                                            />
                                        )}
                                </ListItemAvatar>
                                <Divider className="message-divider" orientation="vertical" />
                                <ListItemText
                                    primary={handleMessageSender(message)}
                                    secondary={handleMessageContent(message.content)}
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                ))}
                <div ref={messagesEndRef} className="auto-focus-div" />
                {props.chatRoom._id && formatedMessages.length === 0 && (
                    <Grid container >
                        <Grid item xs={12}>
                            {props.chatRoom.participants && props.chatRoom.participants.length === 2 ? (
                                <ListItem key={roomAvatar.name} style={{ marginTop: '10vh' }} className="emplty-message-list">
                                    <ListItemAvatar style={{ margin: '2rem' }}>
                                        <Avatar
                                            className="message-avatar-greeding"
                                            variant="square"
                                            src={roomAvatar.image ? roomAvatar.image : `https://avatars.dicebear.com/v2/jdenticon/${roomAvatar.avatar}.svg`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <div className="message-text-greeding">
                                            Send your first message to <u>@{roomAvatar.name}</u>
                                        </div>
                                    </ListItemText>
                                </ListItem>

                            ) : (
                                    <ListItem key={roomAvatar.title} style={{ marginTop: '10vh' }} className="emplty-message-list">
                                        <ListItemAvatar style={{ margin: '2rem' }}>
                                            <Avatar
                                                className="message-avatar-greeding"
                                                variant="square"
                                                src={roomAvatar.image ? roomAvatar.image : `https://avatars.dicebear.com/v2/jdenticon/${roomAvatar.avatar}.svg`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {props.chatRoom.participants ? (
                                                <div style={{ margin: '2vh', fontSize: '22px' }}>
                                                    <p> <u style={{}}>{roomAvatar.creator}</u> created this channel channel at</p>
                                                    <p><u>{moment(roomAvatar.date).format("DD-MM-YYYY hh:mm")}</u></p>
                                                    <p>Be the first to send a message</p>
                                                </div>
                                            ) : (
                                                    <div style={{ margin: '2vh', fontSize: '22px' }}>
                                                        <p>Welcome to Countr {props.chatRoom.type}</p>
                                                        <p>Support cannel</p>
                                                    </div>

                                                )}
                                        </ListItemText>
                                    </ListItem>
                                )}
                        </Grid>
                    </Grid>
                )}
                {!props.chatRoom._id && (
                    <Grid style={{ marginTop: '10vh' }} container className="emplty-message-list">
                        <Grid item xs={12}>
                            <div>
                                {/* <Typography> */}
                                <div style={{ marginTop: '2vh', marginBottom: '1vh' }} className="main-header-logo-text-login">Welcome to</div>
                                <img className="main-header-logo-login" src={Logo} alt="logo" />
                                {/* </Typography> */}
                                <div style={{ marginBottom: '1vh' }} className="main-header-logo-text-login">Chat</div>
                            </div>
                        </Grid>
                    </Grid>

                )}
                {!props.chatRoom.type &&
                    (
                        <ReadBy
                            formatedMessages={formatedMessages} />
                    )}
                {/* <Typing /> */}
            </List>
            {/* <Button onClick={handleMessageLoad} variant="contained" color="primary">Test</Button> */}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Message)
