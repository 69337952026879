import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'

import DashboardSidePanel from './DashboardSidePanel'
import Messages from './Messages/index'

import CountrResources from '../../utils/CountrResources'
import { showToast, setEmployee } from '../../store/actions/app'
import { setAllEmployees, setEmployees } from '../../store/actions/store'
import { setChannelTracker } from '../../store/actions/chatRoom'





const mapStateToProps = state => {
    return {
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: msg => dispatch(showToast(msg)),
        setEmployee: user => dispatch(setEmployee(user)),
        setAllEmployees: emp => dispatch(setAllEmployees(emp)),
        setEmployees: emp => dispatch(setEmployees(emp)),
        setChannelTracker: rooms => dispatch(setChannelTracker(rooms))
    }
}

function DashboardUser(props) {
    const [privateRoooms, setPrivateRooms] = useState([])
    const [groupRooms, setGroupRooms] = useState([])

    const handlePageLoad = async () => {
        let allRooms = await CountrResources.getAllUsersChatRooms()
        let employees = await CountrResources.getEmployees()

        if (employees) {
            props.setAllEmployees(employees)
            props.setEmployees(employees)
        }
        if (allRooms) {
            props.setChannelTracker(allRooms)
            let privateRoom = allRooms.filter(room => room.participants.length === 2)
            setPrivateRooms(privateRoom)
            let group = allRooms.filter(room => room.participants.length > 2)
            setGroupRooms(group)
        }
    }

    useEffect(() => {
        handlePageLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <DashboardSidePanel
                privateRoooms={privateRoooms}
                groupRooms={groupRooms} />

            <Messages />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUser)
