import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'

import CountrResources from '../../../utils/CountrResources'
import { showToast, setEmployee } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AccountCircle from '@material-ui/icons/AccountCircle'
import LockIcon from '@material-ui/icons/Lock'

import '../../../styles/SidePanel.css'




const mapStateToProps = state => {
    return {
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: msg => dispatch(showToast(msg)),
        setEmployee: user => dispatch(setEmployee(user))
    }
}

function CountrAddUser(props) {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const handleCreateNewCountrUser = async () => {
        if (userName.length > 3 && password.length > 3) {
            let newUser = {
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    number: '',
                    state: '',
                    zip: ''
                },
                email: '',
                first_name: userName,
                last_name: 'Countr Support',
                merchant: props.countrMerchant,
                name: `${userName} Countr Support`,
                options: { color: 'greyblue' },
                pin: password,
                rights: 'user',
                token: ''
            }
            let countrNew = await CountrResources.postNewCountrUser(newUser)
            if (countrNew) {
                props.showToast('User created')
                props.handleClose()
            } else {
                props.showToast('Something went wrong, please try again')
            }
        } else {
            props.showToast('Provide valid e-mail and pin')
        }

    }

    const handleUpdatePassword = async () => {
        let updated = await CountrResources.updateUsersPin(newPassword, props.app.employeeSelected._id)
        if (updated) {
            props.showToast('Password updated')
            props.setEmployee(updated)
            props.handleClose()
        } else {
            props.showToast('Something went wrong')
        }
    }

    const handlePasswordCheck = () => {
        if (oldPassword === props.app.employeeSelected.pin) {
            return false
        } else return true
    }

    const handleEmployeesCount = async () => {
        let employeesCount = await CountrResources.getEmployeesCount()
        if (employeesCount) {
            setPassword(`Password${employeesCount}`)
        }
    }

    useEffect(() => {
        if (props.countrModalMode === 'NEW') {
            handleEmployeesCount()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="info-room-header">
                    {props.countrModalMode === 'NEW' ? (
                        <Typography> Add new Countr User </Typography>
                    ) : (
                            <Typography> Change your password </Typography>
                        )}
                </DialogTitle>

                {props.countrModalMode === 'NEW' ? (
                    <DialogContent>
                        <Grid container >

                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    label='Name'
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}


                                />
                            </Grid>

                            <Grid item xs={5} className="countr-fonts countr-last-name">
                                @ Countr Support
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '2vh' }}>
                            <TextField
                                variant="outlined"
                                label='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </DialogContent>

                ) : (
                        <DialogContent>
                            <Typography className="countr-fonts">Entrer your old passord and then your new one</Typography>
                            <Grid item xs={12} style={{ marginTop: '2vh' }}>
                                <TextField
                                    variant="outlined"
                                    label='Old Password'
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '4vh' }}>
                                <TextField
                                    variant="outlined"
                                    label='New Password'
                                    disabled={handlePasswordCheck()}
                                    error={handlePasswordCheck()}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                        </DialogContent>
                    )}


                <DialogActions style={{ marginTop: '2vh' }}>

                    <Button className="new-group-btns"
                        variant="contained"
                        color="secondary"
                        onClick={() => props.handleClose()}
                    >
                        close
                    </Button>

                    {props.countrModalMode === 'NEW' ? (
                        <Button className="new-group-btns"
                            variant="contained"
                            color="primary"
                            onClick={() => handleCreateNewCountrUser()}
                        >
                            create
                        </Button>

                    ) : (
                            <Button className="new-group-btns"
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdatePassword()}
                            >
                                update
                            </Button>
                        )}
                </DialogActions>

            </Dialog>

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrAddUser)
