import React, { useEffect } from 'react'
import '../styles/Loader.css'

const Loader = React.memo(function Loader(props) {

  useEffect(() => {
    if (props.loading === true) {

      const timer = () =>
        setTimeout(() => {
          props.handleChatSockets()
        }, 3000)

      const timerId = timer()
      return () => {
        clearTimeout(timerId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading])

  return (
    <div className="backdrop">
      <div className="spinner">
        <div className="cube cube0" />
        <div className="cube cube1" />
        <div className="cube cube2" />
        <div className="cube cube3" />
        <div className="cube cube4" />
        <div className="cube cube5" />
        <div className="cube cube6" />
        <div className="cube cube7" />
        <div className="cube cube8" />
        <div className="cube cube9" />
        <div className="cube cube10" />
        <div className="cube cube11" />
        <div className="cube cube12" />
        <div className="cube cube13" />
        <div className="cube cube14" />
        <div className="cube cube15" />
        <div className="cube cube16" />
        <div className="cube cube17" />
        <div className="cube cube18" />
        <div className="cube cube19" />
      </div>
    </div>
  )
})

export default Loader
