import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Main from '../components/Main'
import Countr from '../components/Countr'
import Login from '../components/Login/index'
import EmployeeSelection from '../components/Login/EmployeeSelection'
import StoreSelection from '../components/Login/StoreSelection'
import DashboardUser from '../components/DashboardUser'

import '../styles/App.css'


const mapStateToProps = state => {
  return {
    app: state.app
  }
}

function Root(props) {
  const isAuth = Object.keys(props.app.user).length ? true : false
  const isDashboar = props.app.isDasUser ? true : false
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          style={{ position: 'relative', zIndex: '2' }}
          path="/"
          render={({ history }) => <Login history={history} />}
        />
        <Route
          exact
          path="/store"
          render={({ history }) =>
            isAuth ? <StoreSelection history={history} /> : <Redirect to="/" />
          }
        />

        <Route
          exact
          path="/employee"
          render={({ history }) =>
            isAuth ? <EmployeeSelection history={history} /> : <Redirect to="/" />
          }
        />
        <Route
          exact
          path="/main"
          render={({ history }) =>
            isAuth ? <Main history={history} /> : <Redirect to="/" />
          }
        />
        <Route
          exact
          path="/countr"
          render={({ history }) =>
            isAuth ? <Countr history={history} /> : <Redirect to="/" />
          }
        />
        <Route
          exact
          path="/dashboard"
          render={({ history }) =>
            isDashboar ? <DashboardUser history={history} /> : <Redirect to="/" />
          }
        />
        {/* <Route exact path="/main" render={({ history }) => <Main history={history} />} /> */}
        <Route path="*" render={({ history }) => <Login history={history} />} />
      </Switch>
    </div>
  );
}

export default connect(mapStateToProps)(withRouter(Root))
