import { SET_STORE_ID, SET_STORE, SET_EMPLOYEES, SET_ADMINS, SET_ALL_EMPLOYEES } from '../actions/actionTypes'

const initialState = {
  id: '',
  store: {},
  employees: [],
  admins: [],
  allEmployees: [],
  newMessages: []
}

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_ID:
      return {
        ...state,
        id: action.payload
      }
    case SET_STORE:
      return {
        ...state,
        store: action.payload
      }
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload
      }
    case SET_ADMINS:
      return {
        ...state,
        admins: action.payload
      }
    case SET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload
      }
    default:
      return state
  }
}

export default storeReducer
