import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'

import CountrResources from '../../utils/CountrResources'
import { AppInstances } from "../../utils/CountrSdk"
import { showToast } from '../../store/actions/app'
import { setAllEmployees } from '../../store/actions/store'
import { setNewSendMessage, setParticipants, setChannelTracker } from '../../store/actions/chatRoom'
import {
    setUpdatedChannel,
    setChannelTyping,
    setNewChannel,
    setUpdatedTracker,
    setNewIncomingMessage,
    setDeletedChannel,
    setOnlineUpdate,
    setNewSupportChannel
} from '../../store/actions/sockets'

import CountrSidePanel from './CountrComponents/CountrSidePanel'
import Messages from '../Main/Messages'
import Loader from '../../components/Loader'


const mapStateToProps = (state) => {
    return {
        app: state.app,
        store: state.store,
        chatRoom: state.chatRoom,
        sockets: state.sockets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToast: msg => dispatch(showToast(msg)),
        setAllEmployees: emps => dispatch(setAllEmployees(emps)),
        setChannelTracker: channel => dispatch(setChannelTracker(channel)),
        setNewIncomingMessage: msg => dispatch(setNewIncomingMessage(msg)),
        setNewSendMessage: msg => dispatch(setNewSendMessage(msg)),
        setUpdatedTracker: tracker => dispatch(setUpdatedTracker(tracker)),
        setChannelTyping: typing => dispatch(setChannelTyping(typing)),
        setNewChannel: channel => dispatch(setNewChannel(channel)),
        setUpdatedChannel: channel => dispatch(setUpdatedChannel(channel)),
        setDeletedChannel: channel => dispatch(setDeletedChannel(channel)),
        setOnlineUpdate: user => dispatch(setOnlineUpdate(user)),
        setParticipants: channel => dispatch(setParticipants(channel)),
        setNewSupportChannel: channel => dispatch(setNewSupportChannel(channel))
    }
}

const CountrMain = React.memo(props => {
    const [privateChannels, setPrivateChannels] = useState([])
    const [groupChannels, setGroupChannels] = useState([])
    const [loading, setLoading] = useState(false)
    const [privateSupport, setPrivateSupport] = useState([])
    const [storeSupport, setStoreSupport] = useState([])


    const handelSupportChannels = async () => {
        let allSupportChannels = await CountrResources.getAllSupportChannels()
        if (allSupportChannels) {
            let storeSupportChannelsUnread = allSupportChannels.filter(channel => channel.type === 'Store' && channel.tracker.countr === true)
            let storeSupportChannelsRead = allSupportChannels.filter(channel => channel.type === 'Store' && channel.tracker.countr === false)
            setStoreSupport([...storeSupportChannelsUnread, ...storeSupportChannelsRead])
            let privateSupportChannelsUnread = allSupportChannels.filter(channel => channel.type === 'Private' && channel.tracker.countr === true)
            let privateSupportChannelsRead = allSupportChannels.filter(channel => channel.type === 'Private' && channel.tracker.countr === false)
            setPrivateSupport([...privateSupportChannelsUnread, ...privateSupportChannelsRead])
            handleCountrChannels(allSupportChannels)
        }
    }

    const handleCountrChannels = async (supportChannels) => {
        let privateChannels = []
        let group = []
        let newlyCreated = []
        let storeEmployees = props.store.employees.filter(emp => emp._id !== props.app.employeeSelected._id)
        props.setAllEmployees(storeEmployees)

        let channels = await CountrResources.getAllUsersChatRooms()
        if (channels) {
            channels.forEach(channel => {
                if (channel.participants.length === 2) {
                    //Check for private channels
                    let participate = channel.participants.find(person => person === props.app.employeeSelected._id)
                    if (participate) {
                        privateChannels.push(channel)
                    }
                } else {
                    //Check for group channels
                    let participate = channel.participants.find(person => person === props.app.employeeSelected._id)
                    if (participate) {
                        group.push(channel)
                    }
                }
            })
        }
        //Set group channels
        setGroupChannels(group)
        //Check to see if new employees are add and create channel
        if (privateChannels.length === storeEmployees.length) {
            setPrivateChannels(privateChannels)
        } else if (privateChannels.length !== storeEmployees.length && privateChannels.length === 0) {
            //If no channels creatwed
            storeEmployees.forEach(async emp => {
                //Create new channel for users without one
                let trackerArr = []
                let ids = [props.app.employeeSelected._id, emp._id]

                ids.forEach(id => {
                    let track = {
                        participant_id: id,
                        message_no: 0
                    }
                    trackerArr.push(track)
                })

                let newChannel = {
                    merchant: props.app.user._id,
                    creator: ids,
                    title: `${emp.first_name} - ${props.app.employeeSelected.first_name}`,
                    participants: ids,
                    viewers: emp.rights,
                    messages: [],
                    tracker: trackerArr,
                }
                //Send the request for new channels
                let newCreated = await CountrResources.postNewChatRoom(newChannel)
                if (newCreated) {
                    newlyCreated.push(newCreated)
                }
                //Add new channels to existing ones and notify user
                if (newlyCreated.length > 0) {
                    setPrivateChannels([...privateChannels, ...newlyCreated])
                    props.showToast(`${newlyCreated.length} new channels created`)
                    handleChannelsTracker(newlyCreated)
                }
            })

        } else {
            //Find users without channel 
            storeEmployees.forEach(emp => {
                privateChannels.forEach(async channel => {
                    let hasChannel = channel.participants.find(id => id === emp._id)
                    if (!hasChannel) {
                        //Create new channel for users without one
                        let trackerArr = []
                        let ids = [props.app.employeeSelected._id, emp._id]

                        ids.forEach(id => {
                            let track = {
                                participant_id: id,
                                message_no: 0
                            }
                            trackerArr.push(track)
                        })

                        let newChannel = {
                            merchant: props.app.user._id,
                            creator: ids,
                            title: `${emp.first_name} - ${props.app.employeeSelected.first_name}`,
                            participants: ids,
                            viewers: emp.rights,
                            messages: [],
                            tracker: trackerArr,
                        }
                        //Send the request for new channels
                        let newCreated = await CountrResources.postNewChatRoom(newChannel)
                        if (newCreated) {
                            newlyCreated.push(newCreated)
                        }
                    }
                    //Add new channels to existing ones and notify user
                    if (newlyCreated.length > 0) {
                        setPrivateChannels([...privateChannels, ...newlyCreated])
                        props.showToast(`${newlyCreated.length} new channels created`)
                        handleChannelsTracker(newlyCreated)
                    }
                })
            })
        }
        let allChannels = [...privateChannels, ...group, ...supportChannels]
        handleChannelsTracker(allChannels)
        //create sockets for each channel
        allChannels.forEach(cannel => {
            handleChatSockets(cannel)
        })
    }

    const handleChannelsTracker = (channels) => {
        let roomsToTrack = props.chatRoom.channelTracker
        channels.forEach(room => {
            roomsToTrack.push({
                id: room._id,
                messages_no: room.messages.length
            })
            props.setChannelTracker(roomsToTrack)
        })

    }

    const handleUpdateChannel = async () => {
        if (props.chatRoom._id && !props.chatRoom.type) {
            let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
            let room = {
                chatchannel: props.chatRoom._id,
                employeeId: props.app.employeeSelected._id,
                message_no: roomTracker.messages_no,
            }

            let updated = await CountrResources.updateTracker(room)
            if (updated && updated.participants.length > 2) {
                let group = groupChannels.find(id => id._id === updated._id)

                if (group) {
                    let groupIndex = groupChannels.findIndex(id => id._id === updated._id)
                    let oldGroup = groupChannels.filter(id => id._id !== updated._id)
                    oldGroup.splice(groupIndex, 0, updated)
                    // oldGroup.unshift(updated)
                    setGroupChannels(oldGroup)

                }
            } else {
                let channel = privateChannels.find(id => id._id === updated._id)
                if (channel) {
                    let groupIndex = privateChannels.findIndex(id => id._id === updated._id)
                    let oldGroup = privateChannels.filter(id => id._id !== updated._id)
                    oldGroup.splice(groupIndex, 0, updated)
                    setPrivateChannels(oldGroup)
                }
            }

        }
    }

    const handleNewCreatedGroup = (room) => {
        let groups = [...groupChannels]
        groups.push(room)
        if (groups.length > 0) {
            setGroupChannels(groups)
        }
        handleChatSockets(room)
    }

    const handleDeleteUsersChannel = (deleted) => {
        if (groupChannels.length > 0) {
            props.setParticipants(groupChannels[0])
        } else if (privateChannels.length > 0) {
            props.setParticipants(privateChannels[0])
        } else window.location.reload()

        let newGroups = groupChannels.filter(room => room._id !== deleted._id)
        setGroupChannels(newGroups)
    }


    //=======SOCKETS========>

    const handleChatSockets = async (room) => {
        let ids = []
        let rooms = []
        const countr = await AppInstances.getCountrSdk()

        if (room && !room.length) {
            ids.push(room)
        } else ids = rooms

        ids.forEach(room => {
            let isParticipant = room.participants && room.participants.find(id => id === props.app.employeeSelected._id)
            if (isParticipant || room.type) {
                //Set tracker with the original messages.length
                //I use this way to counter some pagination bugs on the tracker
                let roomsToTrack = props.chatRoom.channelTracker
                roomsToTrack.push({
                    id: room._id,
                    messages_no: room.messages.length
                })
                props.setChannelTracker(roomsToTrack)
                //Listen for new messages
                countr.ws.on(`c${room._id}:message.created`, function (body) {
                    if (body.sender !== props.app.employeeSelected._id) {
                        if (body.sender.includes("Countr Support")) {
                            props.setNewSendMessage(body)
                        } else props.setNewIncomingMessage(body)
                    } else {
                        props.setNewSendMessage(body)
                    }
                })
                // Listen channel read messages status
                countr.ws.on(`c${room._id}:channel.tracker`, function (body) {
                    if (body.tracker && body._id) {
                        props.setUpdatedTracker(body)
                    }
                })
                // Listen channel typing status
                // countr.ws.on(`c${room._id}:channel.typing`, function (body) {
                //   if (body.chatchannel && body.typingId !== props.app.employeeSelected._id) {
                //     props.setChannelTyping(body)
                //   }
                // })

            }
        })
        if (!room) {
            // Listen for new channels created
            countr.ws.on(`m${props.app.user._id}:channel.created`, function (body) {
                if (body._id && body.participants) {
                    props.setNewChannel(body)
                }
            })
            // Listen for new support channel created
            countr.ws.on(`m${props.app.user._id}:channel.support.created`, function (body) {
                if (body._id) {
                    props.setNewSupportChannel(body)
                }
            })
            // Listen for channel participant updates
            countr.ws.on(`m${props.app.user._id}:channel.updated`, function (body) {
                if (body._id) {
                    props.setUpdatedChannel(body)
                }
            })
            // Listen for channel deleted
            countr.ws.on(`m${props.app.user._id}:channel.deleted`, function (body) {
                if (body._id) {
                    props.setDeletedChannel(body)
                }
            })
            // Listen for employee updates
            countr.ws.on(`m${props.app.user._id}:employee.updated`, function (body) {
                if (body.length > 0) {
                    props.setOnlineUpdate(body)
                } else if (body._id) {
                    props.setOnlineUpdate(body)
                }
            })
            setLoading(false)
        }
    }

    const handleChannelTrackerUpdate = (room) => {
        let filtered = props.chatRoom.channelTracker.find(id => id.id === room._id)
        filtered.messages_no = filtered.messages_no + 1
        let updated = props.chatRoom.channelTracker.filter(id => id.id !== room._id)
        updated.push(filtered)
        props.setChannelTracker(updated)

    }

    const handleNewMessages = async () => {
        if (props.sockets.incomingMessage.supportChannel) {
            //If new message put cannel on top
            let storeSupportCnls = storeSupport.filter(cnl => cnl._id !== props.sockets.incomingMessage.supportChannel)
            let privateSupportCnls = privateSupport.filter(cnl => cnl._id !== props.sockets.incomingMessage.supportChannel)

            if (storeSupportCnls.length !== storeSupport.length) {
                let channel = storeSupport.find(cnl => cnl._id === props.sockets.incomingMessage.supportChannel)
                channel.unread = true
                setStoreSupport([channel, ...storeSupportCnls])
            } else if (privateSupportCnls.length !== privateSupport.length) {
                let channel = privateSupport.find(cnl => cnl._id === props.sockets.incomingMessage.supportChannel)
                channel.unread = true
                setPrivateSupport([channel, ...privateSupportCnls])
            }

        } else {
            if (props.chatRoom._id !== props.sockets.incomingMessage.chatchannel) {
                let group = groupChannels.find(id => id._id === props.sockets.incomingMessage.chatchannel)
                let privateRoom = privateChannels.find(id => id._id === props.sockets.incomingMessage.chatchannel)


                if (group) {
                    handleChannelTrackerUpdate(group)
                    group.messages.push(props.sockets.incomingMessage)
                    let updated = groupChannels.filter(id => id._id !== group._id)
                    updated.unshift(group)
                    setGroupChannels(updated)

                } else if (privateRoom) {

                    handleChannelTrackerUpdate(privateRoom)
                    privateRoom.messages.push(props.sockets.incomingMessage)
                    let updated = privateChannels.filter(id => id._id !== privateRoom._id)
                    updated.unshift(privateRoom)
                    setPrivateChannels(updated)
                }

            } else if (props.chatRoom._id === props.sockets.incomingMessage.chatchannel) {
                let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
                let room = {
                    chatchannel: props.chatRoom._id,
                    employeeId: props.app.employeeSelected._id,
                    message_no: Number(roomTracker.messages_no) + 1,
                }

                let updated = await CountrResources.updateTracker(room)
                if (updated.error) {
                    props.showToast(updated.error)
                }
            }
        }
    }


    useEffect(() => {
        if (props.sockets.incomingMessage._id) {
            handleNewMessages()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.incomingMessage])

    useEffect(() => {
        if (props.chatRoom.messages.length > 0) {
            handleUpdateChannel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatRoom._id])

    useEffect(() => {
        if (props.sockets.newChannel._id) {
            let included = props.sockets.newChannel.participants.find(id => id === props.app.employeeSelected._id)
            if (props.sockets.newChannel.participants.length > 2 && included) {
                handleNewCreatedGroup(props.sockets.newChannel)

                let creator = props.sockets.newChannel.creator.find(id => id === props.app.employeeSelected._id)
                if (!creator) {
                    props.showToast(`New group channel "${props.sockets.newChannel.title}" created`)
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.newChannel])

    useEffect(() => {
        if (props.sockets.newSupportChannel._id) {
            if (props.sockets.newSupportChannel.type === 'Private') {
                setPrivateSupport([...privateSupport, props.sockets.newSupportChannel])
            } else if (props.sockets.newSupportChannel.type === 'Store') {
                setStoreSupport([...storeSupport, props.sockets.newSupportChannel])
            }
            handleChatSockets(props.sockets.newSupportChannel)
            props.showToast(`New support channel "${props.sockets.newSupportChannel.title}" created`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.newSupportChannel])


    useEffect(() => {
        if (props.sockets.updatedChannel._id) {
            if (props.chatRoom._id === props.sockets.updatedChannel._id) {
                let included = props.sockets.updatedChannel.participants.find(emp => emp._id === props.app.employeeSelected._id)
                if (included) {
                    props.setParticipants(props.sockets.updatedChannel)
                } else {
                    handleDeleteUsersChannel(props.sockets.updatedChannel)
                }
            } else {
                let isNewChannel = groupChannels.find(room => room._id === props.sockets.updatedChannel._id)

                if (!isNewChannel) {
                    handleNewCreatedGroup(props.sockets.updatedChannel)
                    props.showToast(`New group channel "${props.sockets.updatedChannel.title}" created`)
                } else props.showToast(`Group channel "${props.sockets.updatedChannel.title}" updated`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.updatedChannel])

    useEffect(() => {
        if (props.sockets.deletedChannel._id) {
            let newRooms = groupChannels.filter(room => room._id !== props.sockets.deletedChannel._id)
            setGroupChannels(newRooms)
            if (props.sockets.deletedChannel._id === props.chatRoom._id) {
                if (newRooms.length > 0) {
                    props.setParticipants(newRooms[0])
                } else {
                    handleDeleteUsersChannel(props.sockets.deletedChannel)
                }
            }
            props.showToast(`Group channel "${props.sockets.deletedChannel.title}" is deleted`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.deletedChannel])

    useEffect(() => {
        if (props.sockets.onlineUpdate.length > 1) {
            let updated = []
            props.store.allEmployees.forEach(emp => {
                if (emp._id === props.sockets.onlineUpdate) {
                    emp.online = true
                    updated.push(emp)
                } else updated.push(emp)
            })
            props.setAllEmployees(updated)

        } else if (props.sockets.onlineUpdate._id) {
            let updated = []
            props.store.allEmployees.forEach(emp => {
                if (emp._id === props.sockets.onlineUpdate._id) {
                    emp.online = false
                    updated.push(emp)
                } else updated.push(emp)
            })
            props.setAllEmployees(updated)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.onlineUpdate])

    useEffect(() => {
        if (props.sockets.closedConvresation.type && props.sockets.closedConvresation.type === 'Private') {
            let others = privateSupport.filter(channel => channel._id !== props.sockets.closedConvresation._id)
            setPrivateSupport([...others, props.sockets.closedConvresation])
        } else if (props.sockets.closedConvresation.type && props.sockets.closedConvresation.type === 'Store') {
            let others = storeSupport.filter(channel => channel._id !== props.sockets.closedConvresation._id)
            setStoreSupport([...others, props.sockets.closedConvresation])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.closedConvresation])


    //<=======SOCKETS========

    useEffect(() => {
        handelSupportChannels()
        setLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {loading ? (
                <Loader
                    loading={loading}
                    handleChatSockets={handleChatSockets} />

            ) : (
                    <div>
                        <CountrSidePanel
                            groupChannels={groupChannels}
                            privateChannels={privateChannels}
                            privateSupport={privateSupport}
                            storeSupport={storeSupport}
                        />
                        <Messages />
                    </div>
                )}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CountrMain)

