import React, { useState } from 'react'
import { connect } from 'react-redux'

import CountrResources from '../../../utils/CountrResources'
import { openUploadWidget } from "../../../utils/CloudinaryResources"

import { showToast, setEmployee, setLoading } from '../../../store/actions/app'
import { setParticipants } from '../../../store/actions/chatRoom'
import { setAllEmployees } from '../../../store/actions/store'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Avatar from '@material-ui/core/Avatar'
import ImageUploading from "react-images-uploading"
import Grid from '@material-ui/core/Grid'

import '../../../styles/NewGroupModal.css'
import { Card } from '@material-ui/core'


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        showToast: msg => dispatch(showToast(msg)),
        setEmployee: emp => dispatch(setEmployee(emp)),
        setLoading: status => dispatch(setLoading(status)),
        setAllEmployees: emps => dispatch(setAllEmployees(emps))
    }
}


const ChangeAvatarModal = React.memo(props => {
    const [selectedFile, setSelectedFile] = useState([])

    const handleOpenUploader = tag => {
        const uploadOptions = {
            cloud_name: "countr-prod",
            api_key: '139724634935931',
            upload_preset: 'fvrjpdy2'
        }
        openUploadWidget(uploadOptions, (error, photos) => {
            if (!error) {
                if (photos.event === 'success') {
                    setSelectedFile([photos.info.url])
                }
            } else {
                console.log(error);
            }
        })
    }

    const handleUrerAvatar = () => {
        if (props.app.employeeSelected.contactRawSchema) {
            return props.app.employeeSelected.contactRawSchema.image
        } else return `https://avatars.dicebear.com/v2/jdenticon/${props.app.employeeSelected.first_name}${props.app.employeeSelected.last_name}.svg`
    }

    const handleAvatarUpload = async () => {
        let updated = await CountrResources.updateAvatar(props.app.employeeSelected, selectedFile[0])
        props.setLoading(true)
        if (updated) {
            props.setEmployee(updated)
            let employees = props.store.allEmployees.filter(emp => emp._id !== updated._id)
            employees.push(updated)
            props.setAllEmployees(employees)
            props.handleClose()
            props.setLoading(false)
        }
    }

    const onChange = (imageList) => {
        setSelectedFile(imageList);
    }



    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                className="change-avatar-mpdal"
            >
                <DialogTitle id="alert-dialog-title" className="new-group-dialog-header">Change Avatar Image</DialogTitle>
                <DialogContent className="new-group-dialog">
                    <Grid container>
                        <Grid item xs={6}>
                            {!props.app.employeeSelected.avatar && (
                                <Card elevation={4} style={{ width: '70px', marginTop: '2vh' }}>
                                    <Avatar
                                        className="message-avatar-change"
                                        variant="square"
                                        style={{ backgroundColor: '#e8fbe8' }}
                                        src={handleUrerAvatar()}
                                    />
                                </Card>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <p>Select an image to upload</p>
                            <ImageUploading
                                onChange={onChange}
                                maxNumber={10}
                                multiple
                                maxFileSize={5}
                                acceptType={["jpg", "gif", "png"]}
                            >
                                {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                    // write your building UI
                                    <div>
                                        {!selectedFile.length > 0 ? (
                                            <Button onClick={handleOpenUploader}
                                                color="primary"
                                                variant="contained"
                                                className="new-group-btns"
                                            >Upload image</Button>
                                        ) : (
                                                <div style={{ marginTop: '3vh' }}>
                                                    <Button
                                                        fullWidth
                                                        className="new-group-btns"
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ width: '60%' }}
                                                        onClick={handleAvatarUpload}>Update</Button>
                                                    <p style={{ margin: '2vh' }}></p>
                                                    <Button
                                                        fullWidth
                                                        className="new-group-btns"
                                                        color="secondary"
                                                        variant="contained"
                                                        style={{ width: '60%' }}
                                                        onClick={() => setSelectedFile([])}>Remove</Button>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </ImageUploading>
                        </Grid>
                        {selectedFile.length > 0 && (
                            <Grid item xs={6}>
                                <Card elevation={4} style={{ width: '70px', marginTop: '-4vh' }}>
                                    <Avatar
                                        className="message-avatar-change"
                                        variant="square"
                                        style={{ backgroundColor: '#e8fbe8' }}
                                        src={selectedFile[0]}
                                    />
                                </Card>
                            </Grid>
                        )}

                    </Grid>

                </DialogContent>
                <DialogActions className="new-group-dialog" style={{ marginTop: '2vh' }}>
                    <Button
                     color="secondary" 
                     variant="contained"
                     className="new-group-btns"
                     onClick={props.handleClose}>close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAvatarModal)
