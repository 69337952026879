import {
  SET_LOADING,
  SET_USER,
  SET_EMPLOYEE,
  SHOW_TOAST,
  DISMISS_TOAST,
  SET_EMBEDED_DEVICE,
  IS_IFRAME,
  IS_DASHBOARD_USER
} from '../actions/actionTypes'

const initialState = {
  user: {},
  isLoading: false,
  employeeSelected: {},
  showToast: false,
  toastMsg: '',
  currentEmbededDevice: false,
  messengerHeight: 0,
  isIframe: false,
  isDasUser: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case IS_DASHBOARD_USER:
      return {
        ...state,
        isDasUser: action.payload
      }
    case IS_IFRAME:
      return {
        ...state,
        isIframe: action.payload
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_EMPLOYEE:
      return {
        ...state,
        employeeSelected: action.payload
      }
    case SET_EMBEDED_DEVICE:
      return {
        ...state,
        currentEmbededDevice: action.payload
      }
    case SHOW_TOAST:
      return {
        ...state,
        toastMsg: action.payload,
        showToast: true
      }
    case DISMISS_TOAST:
      return {
        ...state,
        toastMsg: '',
        showToast: false
      }
    default:
      return state
  }
}

export default appReducer
