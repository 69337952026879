import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles"

import CountrResources from '../../utils/CountrResources'
import { setParticipants, setRoomLoading, setChannelTracker } from "../../store/actions/chatRoom"

import Logo from "../../assets/countr_white.png"

import StoreIcon from '@material-ui/icons/Store'
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import MenuIcon from "@material-ui/icons/Menu"

import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store,
        chatRoom: state.chatRoom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        setRoomLoading: status => dispatch(setRoomLoading(status)),
        setChannelTracker: channel => dispatch(setChannelTracker(channel))
    }
}


const DashboardSidePanel = React.memo(props => {
    const classes = useStyles()
    const [viewWidth, setViewWidth] = useState(0)
    const [expanded, setExpanded] = useState(false)
    const [mobileView, setMobileView] = useState(false)



    const handleIsActive = (id) => {
        let active = { color: "#ffffff" }
        if (props.chatRoom._id && props.chatRoom._id === id) {
            active = { color: "#00FF00" }
        }

        return active
    }


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }


    const handleChatChange = async (channel) => {
        if (viewWidth < 650) {
            setTimeout(() => {
                setMobileView(mobileView ? false : true)
            }, 500)
        }
        if (!channel.type) {
            props.setRoomLoading(true)
            let room = await CountrResources.getChatRoom(channel._id, true)
            props.setParticipants(room)
            if (room) {
                props.setRoomLoading(false)
            }
        } else {
            props.setRoomLoading(true)
            let room = await CountrResources.getSupportChatRoomCountr(channel._id)
            props.setParticipants(room)

            if (room) {
                props.setRoomLoading(false)
            }

        }
    }



    useEffect(() => {
        const vw = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
        )
        setViewWidth(vw)
        if (vw < 650) {
            setMobileView(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="side-panel">
            {mobileView ? (
                <Collapse in={mobileView} timeout={2000}>
                    <Drawer
                        className="mobile-view-button"
                        variant="persistent"
                        anchor="left"
                        open={mobileView}>
                        <MenuIcon
                            style={{ fontSize: 40 }}
                            button="true"
                            onClick={() => setMobileView(mobileView ? false : true)}
                        />
                    </Drawer>
                </Collapse>
            ) : (
                    <div className={classes.root}>
                        <CssBaseline />
                        <Collapse in={!mobileView}>
                            <Drawer
                                className={classes.drawer}
                                variant="persistent"
                                anchor="left"
                                open={!mobileView}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}>
                                {viewWidth < 650 ? (
                                    <img
                                        className="main-menu-logo-login"
                                        src={Logo}
                                        alt="logo"
                                        button={mobileView ? "true" : "false"}
                                        onClick={() => setMobileView(mobileView ? false : true)}
                                    />
                                ) : (
                                        <img className="main-menu-logo-login" src={Logo} alt="logo" />)}
                                <Typography className="countr-fonts" variant="h6">Chat Dashoard</Typography>

                                <Accordion
                                    style={{ marginTop: "4vh" }}
                                    className="panel-main-menu"
                                    expanded={expanded === "panel3"}
                                    onChange={handleChange("panel3")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <StoreIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography className="countr-fonts">Group Channels</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <List>
                                                    {props.groupRooms.map((room) => (
                                                        <ListItem
                                                            style={handleIsActive(room._id)}
                                                            className="admin-rooms-list"
                                                            button
                                                            key={room._id}
                                                            onClick={() => handleChatChange(room)}
                                                        >
                                                            <ListItemText
                                                                className="chat-room-lsit countr-sub-fonts"
                                                                primary={`@ ${room.title.split("-")[0]}`}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Divider />

                                <Accordion
                                    className="panel-main-menu"
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <AccountCircleIcon className="menu-icons" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography className="countr-fonts">Private Channels</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <List>
                                                    {props.privateRoooms.map((room) => (
                                                        <ListItem
                                                            style={handleIsActive(room._id)}
                                                            className="admin-rooms-list"
                                                            button
                                                            key={room._id}
                                                            onClick={() => handleChatChange(room)}
                                                        >
                                                            <ListItemText
                                                                className="chat-room-lsit countr-sub-fonts"
                                                                primary={`@ ${room.title}`}
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Divider />

                            </Drawer>
                        </Collapse>
                    </div>
                )}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidePanel)


const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#1d212757",
            outline: "1px solid slategrey",
        },
    },

    root: {
        display: "flex",
    },
    appBar: {
        backgroundColor: "#000",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#1d2127",
        color: "#fff",
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        "&::-webkit-scrollbar": {
            width: "0px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))
