import {
    TRACKER_UPDATE,
    SET_IS_TYPING,
    NEW_CHANNEL,
    UPDATE_CHANNEL,
    SET_INCOMING_MSG,
    DELETED_CHANNEL,
    ONLINE_UPDATE,
    NEW_SUPPORT_CHANNEL,
    CLOSE_CONVERSATION,
    EMPLOYEE_LOGOUT
} from './actionTypes'


export const setUpdatedTracker = tracker => ({
    type: TRACKER_UPDATE,
    payload: tracker
})

export const setChannelTyping = typing => ({
    type: SET_IS_TYPING,
    payload: typing
})

export const setNewChannel = channel => ({
    type: NEW_CHANNEL,
    payload: channel
})

export const setUpdatedChannel = channel => ({
    type: UPDATE_CHANNEL,
    payload: channel
})

export const setNewIncomingMessage = msg => ({
    type: SET_INCOMING_MSG,
    payload: msg
})

export const setDeletedChannel = channel => ({
    type: DELETED_CHANNEL,
    payload: channel
})

export const setOnlineUpdate = user => ({
    type: ONLINE_UPDATE,
    payload: user
})

export const setNewSupportChannel = channel => ({
    type: NEW_SUPPORT_CHANNEL,
    payload: channel
})

export const setClosedConversation = cnl => ({
    type: CLOSE_CONVERSATION,
    payload: cnl
})

export const setEmployeeLogout = emp => ({
    type: EMPLOYEE_LOGOUT,
    payload: emp
})