import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import ChatInfoModal from './ChatInfoModal'
import CountrHeader from '../../Countr/CountrComponents/CountrHeader'

import CountrResources from '../../../utils/CountrResources'
import { setParticipants } from '../../../store/actions/chatRoom'
import { showToast } from '../../../store/actions/app'

import Brightness1Icon from '@material-ui/icons/Brightness1'
import PersonIcon from '@material-ui/icons/Person'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AddIcon from '@material-ui/icons/Add'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'

import '../../../styles/Messages.css'


const mapStateToProps = state => {
    return {
        app: state.app,
        chatRoom: state.chatRoom,
        store: state.store
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        showToast: msg => dispatch(showToast(msg))
    }
}


const MessageRoomHeader = React.memo(props => {
    const [personName, setPersonName] = useState([])
    const [addUserToGroup, setAddUserToGroup] = useState(false)
    const [adminView, setAdminView] = useState(false)
    const [chatInfo, setChatInfo] = useState(false)

    const handleChannelName = () => {
        if (props.chatRoom._id) {
            if (props.chatRoom.title.length === 0 && props.chatRoom.creator.length !== 2) {
                return props.app.employeeSelected.name
            } else if (props.chatRoom.title.length !== 0 && props.chatRoom.creator.length !== 2) {
                return props.chatRoom.title
            } else if (props.chatRoom.creator.length === 2) {
                let newTitle = ""
                let thisTitle = props.chatRoom.title.split('-')
                thisTitle.forEach(name => {
                    if (name.trim() !== props.app.employeeSelected.name.trim()) {
                        newTitle = name
                    }
                })
                return newTitle

            }
        }
    }

    const handleAdminView = () => {
        if (props.app.isDasUser) {
            setAdminView(true)
        } else if (props.app.employeeSelected.rights !== 'user' && props.app.employeeSelected.rights !== 'none') {
            let isParticipant = props.chatRoom.participants.find(id => id === props.app.employeeSelected._id)
            if (isParticipant) {
                setAdminView(false)
            } else setAdminView(true)
        }
    }

    const handleAddUserToGroup = () => {
        if (!addUserToGroup) {
            setAddUserToGroup(true)
        } else setAddUserToGroup(false)
    }


    const handleChange = event => {
        setPersonName(event.target.value)
    }

    const handleRenderValue = selected => {
        let list = selected.map(person => person.name)
        return list.join(', ')
    }

    const handleOpenChatInfo = () => {
        if (!chatInfo) {
            setChatInfo(true)
        } else {
            setChatInfo(false)
        }
    }


    const handleAddUserToGroupSubmit = async () => {
        if (personName.length > 2) {
            let old = props.chatRoom
            let updated = {
                _id: old._id,
                title: old.title,
                participants: personName,
                updated_at: new Date(),
                viewers: old.viewers,
                messages: old.messages,
                creator: old.creator
            }

            let newUpdated = await CountrResources.patchChatRoom(updated)

            if (newUpdated) {
                props.setParticipants(newUpdated)
                setAddUserToGroup(false)
            }
        } else {
            props.showToast('Group chat must have at least 3 participants')
        }

    }

    const handleIsOnline = () => {
        let participant = props.chatRoom.participants.filter(emp => emp !== props.app.employeeSelected._id)
        let employee = props.store.allEmployees.find(emp => emp._id === participant[0])

        if (employee.online) {
            return { color: "#44b700" }
        } else return { color: "red" }
    }

    useEffect(() => {
        if (props.chatRoom.participants && props.chatRoom.participants.length > 2) {
            let list = []
            let selected = []
            let inChannel = props.chatRoom.participants
            props.store.employees.forEach(employee => {
                let found = inChannel.find(inside => inside === employee._id)
                if (!found) {
                    list.push(employee)
                } else selected.push(employee)
            })
            setPersonName(selected)
            handleAdminView()
        }
        //    eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chatRoom, props.store])

    return (
        <div>
            <Paper className="message-room-header">
                <Grid container>
                    {!props.chatRoom._id && (
                        <Grid item xs={12} style={{ color: '#F0F0F0' }}>
                            <h2 className="date-header">{moment(new Date()).format("DD-MM-YYYY")}</h2>
                        </Grid>
                    )}
                    {!props.chatRoom.participants && props.chatRoom._id && props.app.user._id !== process.env.REACT_APP_COUNTR_SUPPORT_ID && (
                        <Grid item xs={12} style={{ color: '#F0F0F0' }}>
                            <h2 className="date-header">Countr {props.chatRoom.type} support</h2>
                        </Grid>
                    )}
                    {!props.chatRoom.participants && props.chatRoom._id && props.app.user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID && (
                        <Grid item xs={12}>
                            <CountrHeader />
                        </Grid>
                    )}
                    {props.chatRoom.participants && props.chatRoom.participants.length === 2 && !props.app.isDasUser &&(
                        <Grid item xs={12} className="message-room-user-name">
                            <Brightness1Icon className="logged-status-header" style={handleIsOnline()} />
                            <span>{handleChannelName()}</span><br />
                        </Grid>
                    )}
                    {props.chatRoom.participants && props.chatRoom.participants.length > 2 && (
                        <Grid item xs={12} className="message-room-user-name">
                            <Grid item xs={12}># <span>{handleChannelName()}</span><br /></Grid>
                            <Grid item xs={12}>
                                <PersonIcon style={{ fontSize: '28px', color: "#C0C0C0" }} />
                                <span className="group-users-txt">
                                    {props.chatRoom.participants.length}
                                    <span style={{ fontWeight: 'normal', marginLeft: '0.5rem', color: "#C0C0C079" }}>|</span>
                                    <Button
                                        style={{ color: '#C0C0C0' }}
                                        onClick={handleOpenChatInfo}
                                    >chat info <InfoOutlinedIcon className="chat-info-btn" />
                                    </Button>
                                    <span style={{ fontWeight: 'normal', marginLeft: '0.5rem', color: "#C0C0C079" }}>|</span>
                                    {!adminView && (
                                        <Button
                                            style={{ color: '#C0C0C0' }}
                                            onClick={handleAddUserToGroup}
                                        >add user <AddIcon className="chat-info-btn" />
                                        </Button>
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            {addUserToGroup && (
                <Dialog
                    open={addUserToGroup}>
                    <DialogTitle className="add-users-header">
                        Choose users to add in <br />
                        <span><u>#{props.chatRoom.title}</u></span> channel</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl className="change-group-input">
                                    <Select
                                        multiple
                                        id="demo-simple-select"
                                        value={personName}
                                        renderValue={selected => handleRenderValue(selected)}
                                        onChange={handleChange}
                                    >
                                        {props.store.employees.map(employee => (
                                            <MenuItem value={employee} key={employee._id}>
                                                <Checkbox color="primary" checked={personName.indexOf(employee) > -1} />
                                                <ListItemText primary={employee.name} />
                                            </MenuItem>

                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions style={{ marginTop: '2vh' }}>
                        <Button className="new-group-btns" variant="contained" color="primary" onClick={handleAddUserToGroupSubmit}>update</Button>

                        <Button className="new-group-btns" variant="contained" color="secondary" onClick={handleAddUserToGroup}>close</Button>
                    </DialogActions>
                </Dialog>
            )}
            {chatInfo && (
                <ChatInfoModal
                    open={chatInfo}
                    onClose={handleOpenChatInfo}
                    room={props.chatRoom}
                />
            )}
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageRoomHeader)
