import React, { useState } from 'react'
import { connect } from 'react-redux'

import CountrResources from '../../../utils/CountrResources'
import { setClosedConversation } from '../../../store/actions/sockets'

import InfoIcon from '@material-ui/icons/Info'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'



const mapDispatchToProps = dispatch => {
    return {
        setClosedConversation: channel => dispatch(setClosedConversation(channel))
    }
}

const mapStateToProps = state => {
    return {
        chatRoom: state.chatRoom,
    }
}

const CountrHeader = React.memo(props => {
    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleCloseConversation = async channel => {
        channel.tracker.countr = false
        const closeConversation = await CountrResources.patchChatRoomCountr(channel)
        if (closeConversation) {
            props.setClosedConversation(closeConversation)
        }
    }


    return (
        <div className="message-room-header">
            <Grid container >
                <Grid item xs={1}>
                    <IconButton onClick={handleClick}>
                        <InfoIcon
                            fontSize='large'
                            style={{ color: '#F0F0F0', marginTop: '10px', marginLeft: '10px' }} />
                    </IconButton>
                </Grid>
                <Grid item xs={8} style={{ color: '#F0F0F0' }}>
                    <h2 className="date-header">
                        {props.chatRoom.title}
                    </h2>
                </Grid>
                <Grid item xs={3}>
                    <Grid container alignItems='flex-end' justify='flex-end'>
                        <Button
                            variant="contained"
                            onClick={() => handleCloseConversation(props.chatRoom)}
                            style={{ backgroundColor: '#8F0C00', marginTop: '20px', marginRight: '10px' }}>
                            Close Conversation
                            </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ backgroundColor: '#1d2127', margin: '10px' }}>
                    <h3 className="popover-info"> Merchant id: {props.chatRoom.merchant} </h3>
                    <h3 className="popover-info"> Store id : {props.chatRoom.store}</h3>
                </div>
            </Popover>

        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CountrHeader)
