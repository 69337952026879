import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Picker from 'emoji-picker-react'

import { setNewSendMessage } from '../../../store/actions/chatRoom'
import { showToast } from '../../../store/actions/app'
import CountrResources from '../../../utils/CountrResources'

import SentimentSatisfiedRoundedIcon from '@material-ui/icons/SentimentSatisfiedRounded'
import CancelIcon from '@material-ui/icons/Cancel'

import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'

import '../../../styles/Messages.css'
import { Popover, IconButton } from '@material-ui/core'


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store,
        chatRoom: state.chatRoom,
        sockets: state.sockets
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNewSendMessage: user => dispatch(setNewSendMessage(user)),
        showToast: msg => dispatch(showToast(msg)),
    }
}



const SendMessage = React.memo(props => {
    const [message, setMessage] = useState('')
    const [rows, setRows] = useState("1")
    // const [isTyping, setIsTyping] = useState(false)
    const [openEmojiModal, setOpenEmojiModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [emojis, setEmojis] = useState([])
    const [msgCount, setMsgCount] = useState(1)

    const handleMessage = event => {
        setMessage(event)
        // if (event.length > 0) {
        //     setIsTyping(true)

        // } else setIsTyping(false)
    }

    const handleEnterPress = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (message.trim().length === 0) {
                props.showToast('message cant be empty')
            } else {
                event.preventDefault()
                setMessage('')
                setRows("1")
                if (props.chatRoom.participants) {
                    handleSendMessage(message)
                } else {
                    handleSupportMessage(message)
                }
                // setIsTyping(false)
            }
        } else setRows("20")

    }

    const handleSupportMessage = async msg => {
        let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)

        let sendMessage = {
            merchant: process.env.REACT_APP_COUNTR_SUPPORT_ID === props.app.user._id ? props.chatRoom.merchant : props.app.user._id,
            sequence_no: Number(roomTracker).messages_no + 1,
            sender: process.env.REACT_APP_COUNTR_SUPPORT_ID === props.app.user._id ? 'Countr Support' : props.chatRoom.title,
            date: new Date(),
            content: msg,
        }

        let updatedTracker = {
            user: props.app.user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID ? true : false,
            countr: props.app.user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID ? false : true
        }


        let room = {
            _id: props.chatRoom._id,
            title: props.chatRoom.title,
            merchant: props.chatRoom.merchant,
            store: props.chatRoom.store,
            creator: props.chatRoom.creator,
            type: props.chatRoom.type,
            messages: props.chatRoom.messages,
            tracker: updatedTracker
        }
        if (process.env.REACT_APP_COUNTR_SUPPORT_ID === props.app.user._id) {
            let sended = await CountrResources.postCountrSupportMessage(sendMessage, room)
            if (sended) {
                setMsgCount(msgCount + 1)
            }
        } else {
            let sended = await CountrResources.postSupportMessage(sendMessage, room)
            if (sended) {
                if (props.chatRoom.type === 'Private') {
                    localStorage.setItem('PrivateSupportMsgs:', msgCount + 1)
                } else if (props.chatRoom.type === 'Store') {
                    localStorage.setItem('StoreSupportMsgs:', msgCount + 1)
                }
                setMsgCount(msgCount + 1)
            }
        }
    }

    const handleSendMessage = async (msg) => {
        let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)

        let sendMessage = {
            merchant: props.app.user._id,
            sequence_no: Number(roomTracker.messages_no) + Number(msgCount),
            sender: props.app.employeeSelected._id,
            date: new Date(),
            content: msg
        }

        let updatedTracker = []
        props.chatRoom.tracker.forEach(id => {
            if (id.participant_id === props.app.employeeSelected._id) {
                id.message_no = roomTracker.messages_no + msgCount
                updatedTracker.push(id)
            } else updatedTracker.push(id)
        })

        let room = {
            _id: props.chatRoom._id,
            title: props.chatRoom.title,
            merchant: props.chatRoom.merchant,
            creator: props.chatRoom.creator,
            participants: props.chatRoom.participants,
            viewers: props.chatRoom.viewers,
            messages: props.chatRoom.messages,
            tracker: updatedTracker
        }
        let sended = await CountrResources.postMessage(sendMessage, room)
        if (sended) {
            setMsgCount(msgCount + 1)
        }
    }

    const handleEmojiModal = (event) => {
        setOpenEmojiModal(true)
        setAnchorEl(event.currentTarget)
    }

    const handleCloseEmojiModal = () => {
        setAnchorEl(null);
        setOpenEmojiModal(false)
    }


    const onEmojiClick = (event, emojiObject) => {
        let newEmojis = emojis
        newEmojis.push(emojiObject.emoji)
        let messageFormat = message
        newEmojis.forEach(emoji => {
            messageFormat = messageFormat + emoji
        })
        setEmojis(newEmojis)
        setMessage(message + newEmojis)
    }


    // const handleUpdateTyping = async status => {
    //     if (props.chatRoom._id) {
    //         let room = {
    //             chatchannel: props.chatRoom._id,
    //             typingId: props.app.employeeSelected._id,
    //             status: status
    //         }

    //         let typing = await CountrResources.updateTyping(room)

    //         if (typing.error) {
    //             props.showToast(typing.error)
    //         }
    //     }

    // }
    // useEffect(() => {
    //     if (isTyping) {
    //         handleUpdateTyping(true)
    //     } else {
    //         handleUpdateTyping(false)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isTyping])

    useEffect(() => {
        if (props.chatRoom._id) {
            let roomMessages = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
            if (roomMessages && roomMessages.messages_no) {
                setMsgCount(roomMessages.messages_no)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.sockets.incomingMessage.chatchannel && props.sockets.incomingMessage.chatchannel === props.chatRoom._id) {
            setMsgCount(msgCount + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sockets.incomingMessage])

    return (
        <div className="main-message-send-div">
            <Grid container>
                <Grid item xs={11}>
                    {!props.isAdminView && !props.app.isDasUser ? (
                        <TextField
                            disabled
                            className="new-message-input"
                            variant="outlined"
                            fullWidth
                            // label={props.chatRoom.title}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleEmojiModal}>
                                            <SentimentSatisfiedRoundedIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                            <TextField
                                autoFocus={!props.skeletons}
                                id="message-height"
                                className="new-message-input"
                                // label={!userTyping.length > 0 ? `Message ${handleUserName(props.chatRoom.title)}` : `User(s) ${userTyping.join(' ')} typing ...`}
                                multiline
                                variant="outlined"
                                fullWidth
                                rowsMax={rows}
                                value={message}
                                onChange={event => handleMessage(event.target.value)}
                                onKeyPress={event => handleEnterPress(event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleEmojiModal}>
                                                <SentimentSatisfiedRoundedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        )}
                </Grid>
                <Grid item xs={1}><p></p></Grid>
            </Grid>
            <Popover
                // id={id}
                open={openEmojiModal}
                anchorEl={anchorEl}
                onClose={handleCloseEmojiModal}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <IconButton onClick={handleCloseEmojiModal} variant="raised" style={{ backgroundColor: 'transparent' }} disableRipple>
                    <CancelIcon style={{ color: 'red', marginLeft: '10em' }} />
                </IconButton>

                <Picker onEmojiClick={onEmojiClick} />
            </Popover>
        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(SendMessage)
