import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import CountrResources from '../../../utils/CountrResources'
import { showToast } from '../../../store/actions/app'
import { setParticipants } from '../../../store/actions/chatRoom'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import '../../../styles/NewGroupModal.css'
import DialogContentText from '@material-ui/core/DialogContentText'


const mapStateToProps = state => {
    return {
        app: state.app,
        store: state.store
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setParticipants: user => dispatch(setParticipants(user)),
        showToast: msg => dispatch(showToast(msg))
    }
}


const NewGroupChat = React.memo(props => {
    const [personName, setPersonName] = useState([])
    const [employeesList, setEmployeesList] = useState([])
    const [channelName, setChannelName] = useState("")
    const [chatViewrs, setChatViewrs] = useState('')
    const [lengthError, setLenghtError] = useState(false)
    const [nameError, setNameError] = useState(false)


    const handleChange = event => {
        setPersonName(event.target.value)
    }

    const handleChannelName = event => {
        setChannelName(event.target.value)
    }

    const handleRenderValue = selected => {
        let list = selected.map(person => person.name)
        return list.join(', ')
    }

    const handelNewGroupChannel = async () => {
        if (channelName.length > 0 && personName.length > 1) {
            let tracker = []
            let ids = personName.map(person => person._id)
            ids.push(props.app.employeeSelected._id)

            ids.forEach(id => {
                let track = {
                    participant_id: id,
                    message_no: 0
                }
                tracker.push(track)
            })
            let newChannel = {
                merchant: props.app.user._id,
                creator: props.app.employeeSelected._id,
                title: channelName,
                participants: ids,
                viewers: chatViewrs,
                messages: [],
                tracker: tracker
            }
            let newCreated = await CountrResources.postNewChatRoom(newChannel)
            props.setParticipants(newCreated)
            props.handleClose()
            //TODO error hadle
        } else if (channelName.length > 0 && personName.length === 1) {
            props.showToast('The group must hane at least 3 users')
            setLenghtError(true)
        } else if (channelName.length === 0 && personName.length > 1) {
            props.showToast('Please enter a name for your group')
            setNameError(true)
        } else if (channelName.length === 0 && personName.length === 1) {
            props.showToast(`The group must hane at least 3 users and a name`)
            setLenghtError(true)
            setNameError(true)
        }
    }

    const handleChatViewrsChange = rights => {
        setChatViewrs(rights)

    }


    useEffect(() => {
        if (props.app.employeeSelected.rights === 'user' || props.app.employeeSelected.rights === 'none') {
            let list = props.store.employees.filter(employee => employee._id !== props.app.employeeSelected._id)
            setEmployeesList(list)
        } else if (props.app.employeeSelected.rights === 'admin') {
            let list = []
            let ids = []
            let allEmployees = props.store.allEmployees.filter(employee => employee._id !== props.app.employeeSelected._id)
            allEmployees.forEach(employee => {
                let inList = ids.find(id => id === employee._id)
                if (!inList) {
                    if (employee.name) {
                        ids.push(employee._id)
                        list.push(employee)
                    } else {
                        employee = {
                            ...employee,
                            name: `${employee.first_name} ${employee.last_name}`
                        }
                        ids.push(employee._id)
                        list.push(employee)
                    }
                }
            })
            setEmployeesList(list)
        }
        setChatViewrs(props.app.employeeSelected.rights)
    }, [props.app.employeeSelected, props.store])


    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ borderRadius: '25%' }}
            >
                <DialogTitle id="alert-dialog-title" className="new-group-dialog-header countr-fonts">{"New group chat"}</DialogTitle>
                <DialogContent className="new-group-dialog">
                    <Grid container className='box-shadow-form'>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <DialogContentText style={{color: '#000'}}>
                                Choose participants
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Select
                                    variant="outlined"
                                    error={lengthError}
                                    multiple
                                    id="demo-simple-select"
                                    value={personName}
                                    renderValue={selected => handleRenderValue(selected)}
                                    onChange={handleChange}
                                    style={{ marginBottom: '20px' }}
                                >
                                    {employeesList.map(employee => (
                                        <MenuItem value={employee} key={employee._id}>
                                            <Checkbox color="primary" checked={personName.indexOf(employee) > -1} />
                                            <ListItemText primary={employee.name} />
                                        </MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container className='box-shadow-form'>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <DialogContentText style={{color: '#000'}}>
                                Give a name to your channel
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    variant="outlined"
                                    error={nameError}
                                    fullWidth
                                    id="standard-basic"
                                    label="Channel name"
                                    onChange={event => handleChannelName(event)}
                                    value={channelName}
                                />
                            </form>

                        </Grid>
                    </Grid>
                    {props.app.employeeSelected.rights === 'admin' && (
                        <Grid container className="chat-rights-select box-shadow-form">
                            <Grid item xs={12}>
                                <DialogContentText style={{color: '#000'}}>
                                    Choose visibility
                            </DialogContentText>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl>
                                    <Select
                                        variant="outlined"
                                        labelId="demo-simple-select-label"
                                        value={chatViewrs}
                                        onChange={event => handleChatViewrsChange(event.target.value)}
                                        // style={{ marginBottom: '20px' }}
                                    >
                                        <MenuItem value='admin'>Admin</MenuItem>
                                        <MenuItem value='user'>User</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                        </Grid>
                    )}





                </DialogContent>
                <DialogActions className="new-group-dialog" style={{ marginTop: '2vh' }}>
                    <Button color="primary" variant='contained' className="new-group-btns" onClick={handelNewGroupChannel}>ok</Button>

                    <Button color="secondary" variant='contained' className="new-group-btns" onClick={props.handleClose}>close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupChat)

