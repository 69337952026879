import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CountrResources from '../../utils/CountrResources'
import { AppInstances } from "../../utils/CountrSdk"

import {
  setNewSendMessage,
  setParticipants,
  setChannelTracker,
  setStoreSupportChannel,
  setPrivateSupportChannel
} from '../../store/actions/chatRoom'
import {
  setUpdatedChannel,
  setChannelTyping,
  setNewChannel,
  setUpdatedTracker,
  setNewIncomingMessage,
  setDeletedChannel,
  setOnlineUpdate,
  setEmployeeLogout
} from '../../store/actions/sockets'

import { setAdmins, setAllEmployees } from '../../store/actions/store'
import { showToast } from '../../store/actions/app'

import SidePanel from './SidePanel'
import Messages from './Messages'
import Loader from '../../components/Loader'

const mapStateToProps = state => {
  return {
    app: state.app,
    store: state.store,
    chatRoom: state.chatRoom,
    sockets: state.sockets
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAdmins: admins => dispatch(setAdmins(admins)),
    showToast: (msg) => dispatch(showToast(msg)),
    setAllEmployees: employees => dispatch(setAllEmployees(employees)),
    setNewIncomingMessage: msg => dispatch(setNewIncomingMessage(msg)),
    setNewSendMessage: msg => dispatch(setNewSendMessage(msg)),
    setUpdatedTracker: tracker => dispatch(setUpdatedTracker(tracker)),
    setChannelTyping: typing => dispatch(setChannelTyping(typing)),
    setNewChannel: channel => dispatch(setNewChannel(channel)),
    setUpdatedChannel: channel => dispatch(setUpdatedChannel(channel)),
    setParticipants: channel => dispatch(setParticipants(channel)),
    setDeletedChannel: channel => dispatch(setDeletedChannel(channel)),
    setOnlineUpdate: user => dispatch(setOnlineUpdate(user)),
    setChannelTracker: channel => dispatch(setChannelTracker(channel)),
    setStoreSupportChannel: channel => dispatch(setStoreSupportChannel(channel)),
    setPrivateSupportChannel: channel => dispatch(setPrivateSupportChannel(channel)),
    setEmployeeLogout: emp => dispatch(setEmployeeLogout(emp))
  }
}

const Main = React.memo(props => {
  const [groupChatRooms, setGroupChatRooms] = useState([])
  const [privateChatRooms, setPrivateChatRooms] = useState([])
  const [adminChatRooms, setAdminChatRooms] = useState([])
  const [adminPrivateChatRooms, setAdminPrivateChatRooms] = useState([])
  const [adminViewChats, setAdminViewChats] = useState([])
  const [adminViewGroupChats, setAdminViewGroupChats] = useState([])
  const [supportCounter, setSupportCounter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [newSupportMessage, setNewSupportMessage] = useState('')
  const [loadedSockets, setLoadedSockets] = useState(false)
  const [missingRooms, setMissingRooms] = useState(false)
  const [incomingCounter, setIncomingCounter] = useState(1)


  const handleCountrSupport = async () => {
    let countrSupportAll = await CountrResources.getUsersCountrSupport()
    let countrSupport = countrSupportAll.filter(channel => channel.creator === props.store.id || channel.creator === props.app.employeeSelected._id)
    if (countrSupport.length === 0) {
      let storeSupport = {
        title: `${props.store.store.name} - Support`,
        merchant: props.app.user._id,
        store: props.store.id,
        //for store channels creator is store id
        creator: props.store.id,
        type: 'Store',
        messages: [],
        tracker: {
          countr: false
        },
        countr: process.env.REACT_APP_COUNTR_SUPPORT_ID
      }
      let supportChannel = await CountrResources.postNewCountrSupportChannel(storeSupport)
      if (supportChannel) {
        //Set store support
        props.setStoreSupportChannel(supportChannel)
        handleChatSockets(supportChannel)
        let privateSupport = {
          title: `${props.app.employeeSelected.first_name} ${props.app.employeeSelected.last_name} - ( ${props.store.store.name} )`,
          merchant: props.app.user._id,
          store: props.store.id,
          //for private channels creator is employee id
          creator: props.app.employeeSelected._id,
          type: 'Private',
          messages: [],
          tracker: {
            countr: false
          },
          countr: process.env.REACT_APP_COUNTR_SUPPORT_ID
        }
        let privateSupportChannel = await CountrResources.postNewCountrSupportChannel(privateSupport)
        if (privateSupportChannel) {
          //Set private support
          props.setPrivateSupportChannel(privateSupportChannel)
          handleChatSockets(privateSupportChannel)
        }
      }
    } else if (countrSupport.length === 1 && countrSupport[0].type === 'Store') {
      let privateSupport = {
        title: `${props.app.employeeSelected.first_name} ${props.app.employeeSelected.last_name} - ( ${props.store.store.name} )`,
        merchant: props.app.user._id,
        store: props.store.id,
        //for private channels creator is employee id
        creator: props.app.employeeSelected._id,
        type: 'Private',
        messages: [],
        tracker: {
          countr: false
        },
        countr: process.env.REACT_APP_COUNTR_SUPPORT_ID
      }
      let privateSupportChannel = await CountrResources.postNewCountrSupportChannel(privateSupport)
      if (privateSupportChannel) {
        //Set private and store support
        props.setPrivateSupportChannel(privateSupportChannel)
        handleChatSockets(privateSupportChannel)
        props.setStoreSupportChannel(countrSupport[0])
        handleChatSockets(countrSupport[0])
      }
    } else if (countrSupport.length === 2) {
      let privateSupportCnl = countrSupport.find(cnl => cnl.type === 'Private')
      let storeSupportCnl = countrSupport.find(cnl => cnl.type === 'Store')
      //Set private and store support
      props.setStoreSupportChannel(storeSupportCnl)
      handleChatSockets(storeSupportCnl)
      props.setPrivateSupportChannel(privateSupportCnl)
      handleChatSockets(privateSupportCnl)
    } else {
      console.log('error')
    }
  }

  const hadleAdmins = async () => {
    let stores = await CountrResources.getStores()
    let allEmployees = []
    let list = []
    let adminId = []

    stores.forEach(store => {
      store.employees.forEach(employee => {
        allEmployees.push(employee)
        if (employee.rights === 'admin') {
          let storeEmployees = store.employees.filter(emp => emp !== employee._id)
          let istId = adminId.find(id => id === employee._id)
          if (!istId) {
            adminId.push(employee._id)
            let admin = {
              ...employee,
              store: store.name,
              store_employees: [
                {
                  store_name: store.name,
                  store_id: store._id,
                  employees_list: storeEmployees
                }
              ]
            }
            list.push(admin)
          } else if (istId) {
            list.forEach(admin => {
              if (admin._id === istId) {
                admin.store = [admin.store + ` - ${store.name}`]
                admin.store_employees = [
                  ...admin.store_employees,
                  {
                    store_name: store.name,
                    store_id: store._id,
                    employees_list: storeEmployees
                  }
                ]

              }
            })
          }
        }
      })
    })

    props.setAdmins(list)
    props.setAllEmployees(allEmployees)
  }

  const handleUsersRooms = async () => {
    let admins = props.store.employees.filter((emp) => emp.rights === "admin")
    let user = props.app.employeeSelected._id
    let rooms = await CountrResources.getAllUsersChatRooms()
    let group = []
    let privateRooms = []
    let adminRooms = []

    rooms.forEach((room) => {
      if (room.creator.length !== 2) {
        room.participants.forEach((id) => {
          if (id === user) {
            group.push(room)
          }
        })
      } else if (room.creator.length === 2) {
        if (room.participants[0] === user || room.participants[1] === user) {
          room.participants.forEach((participant) => {
            if (participant !== user) {
              let isAdmin = admins.find((admin) => admin._id === participant)

              if (isAdmin && isAdmin._id) {
                room = {
                  ...room,
                  store_name: isAdmin.store,
                }
                adminRooms.push(room)
              } else privateRooms.push(room)
            }
          })
        }
      }
      if (group.length > 0) {
        setGroupChatRooms(group)
      }
      if (privateRooms.length > 0) {
        setPrivateChatRooms(privateRooms)
      }
      if (adminRooms.length > 0) {
        setAdminChatRooms(adminRooms)
      }
    })
    checkPrivateRooms(rooms, privateRooms, adminRooms)
  }

  const handleNewRooms = async (room, privates, adminRooms, rights, user) => {
    let created = await CountrResources.postNewChatRoom(room)

    if (user === "user" || user === "none") {
      if (rights === "user") {
        let list = privates
        list.push(created)
        if (list.length > 0) {
          setPrivateChatRooms(list)
        }
      } else {
        let list = adminRooms
        list.push(created)
        if (list.length > 0) {
          setAdminChatRooms(list)
        }
      }
    }

    if (user === "admin") {
      if (rights === "user") {
        let list = privates
        list.push(created)
        if (list.length > 0) {
          setAdminPrivateChatRooms(list)
        }
      } else if (rights === "admin") {
        let list = adminRooms
        list.push(created)
        if (list.length > 0) {
          setAdminChatRooms(list)
        }
      }
    }
  }

  const checkPrivateRooms = async (rooms, privates, adminRooms) => {
    let notGroups = rooms.filter(
      (room) =>
        room.participants.length === 2 &&
        (room.participants[0] === props.app.employeeSelected._id ||
          room.participants[1] === props.app.employeeSelected._id)
    )
    let roomsLenght = privates.length + adminRooms.length

    // if (props.store.admins.length !== 0) {
    let admins = props.store.employees.filter((emp) => emp.rights === "admin")
    let employees = props.store.employees.filter(
      (employee) =>
        employee._id !== props.app.employeeSelected._id &&
        employee.rights !== "admin"
    )
    let storeEmployees = employees.concat(admins)
    if (storeEmployees.length !== notGroups.length) {
      if (privates.length === 0 && adminRooms.length === 0) {
        storeEmployees.forEach((employee) => {
          let tracker = []
          let ids = [props.app.employeeSelected._id, employee._id]

          ids.forEach((id) => {
            let track = {
              participant_id: id,
              message_no: 0,
            }
            tracker.push(track)
          })

          let newChannel = {
            merchant: props.app.user._id,
            store: [props.store.store._id],
            creator: ids,
            title: `${employee.name} - ${props.app.employeeSelected.name}`,
            participants: ids,
            viewers: employee.rights,
            messages: [],
            tracker: tracker,
          }
          let user = "user"
          handleNewRooms(
            newChannel,
            privates,
            adminRooms,
            employee.rights,
            user
          )
        })

      } else if (roomsLenght < storeEmployees.length) {
        let newRooms = storeEmployees
        notGroups.forEach((room) => {
          storeEmployees.forEach((employee) => {
            if (
              room.participants[0] === employee._id ||
              room.participants[1] === employee._id
            ) {
              newRooms = newRooms.filter((room) => room._id !== employee._id)
            }
          })
        })
        newRooms.forEach((room) => {
          let tracker = []
          let ids = [props.app.employeeSelected._id, room._id]

          ids.forEach((id) => {
            let track = {
              participant_id: id,
              message_no: 0,
            }
            tracker.push(track)
          })

          let newChannel = {
            merchant: props.app.user._id,
            store: [props.store.store._id],
            creator: ids,
            title: `${room.name} - ${props.app.employeeSelected.name}`,
            participants: ids,
            viewers: room.rights,
            messages: [],
            tracker: tracker,
          }
          let user = "user"
          handleNewRooms(newChannel, privates, adminRooms, room.rights, user)
        })

      } else {
        if (employees && (props.app.employeeSelected.rights === "user" || props.app.employeeSelected.rights === "none")) {
          let prvList = []
          employees.forEach((emp) => {
            privates.forEach((prv) => {
              if (
                prv.participants[0] === emp._id ||
                prv.participants[1] === emp._id
              ) {
                prvList.push(prv)
              }
            })
          })
          if (prvList.length > 0) {
            setPrivateChatRooms(prvList)
          }
        } else if (employees && props.app.employeeSelected.rights === "admin") {
          console.log('admin')
        } else setPrivateChatRooms([])
      }
      setMissingRooms(true)
    } else {
      const rooms = await CountrResources.getAllUsersChatRooms()
      handleChatSockets(rooms)
    }

    // }
  }

  const handleUserIsAdmin = async () => {
    let rooms = await CountrResources.getAllUsersChatRooms()
    let groupRooms = rooms.filter((room) => room.participants.length > 2)
    let privateRooms = rooms.filter((room) => room.participants.length === 2)
    let admins = props.store.admins
    let userAdmin = admins.find(
      (admin) => admin._id === props.app.employeeSelected._id
    )
    let otherAdmins = admins.filter(
      (admin) => admin._id !== props.app.employeeSelected._id
    )
    let createdIds = []
    let groupList = []
    let adminList = []
    let privatesList = []

    handleAdminView(rooms, userAdmin)

    if (userAdmin && userAdmin._id) {
      //set admin group rooms
      groupRooms.forEach((room) => {
        let isHis = room.participants.find((id) => id === userAdmin._id)
        if (isHis && isHis.length) {
          groupList.push(room)
        }
      })
      if (groupList.length > 0) {
        setGroupChatRooms(groupList)
      }

      //set admin rooms
      privateRooms.forEach((room) => {
        otherAdmins.forEach((other) => {
          if (
            (room.participants[0] === other._id &&
              room.participants[1] === userAdmin._id) ||
            (room.participants[0] === userAdmin._id &&
              room.participants[1] === other._id)
          ) {
            room = {
              ...room,
              store_name: other.store,
            }
            adminList.push(room)
          }
        })
      })
      if (adminList.length > 0) {
        setAdminChatRooms(adminList)
      }

      //set private rooms and check if he is register in many stores
      if (userAdmin.store_employees.length === 0) {
      } else if (userAdmin.store_employees.length > 0) {
        userAdmin.store_employees.forEach((store) => {
          store.employees_list.forEach((emp) => {
            if (emp.rights === "user" || emp.rights === "none") {
              let existedRoom = privateRooms.find(
                (room) =>
                  (room.participants[0] === emp._id &&
                    room.participants[1] === userAdmin._id) ||
                  (room.participants[0] === userAdmin._id &&
                    room.participants[1] === emp._id)
              )
              if (existedRoom) {
                let isManyStore = createdIds.find(
                  (id) => id === existedRoom._id
                )
                if (!isManyStore) {
                  createdIds.push(existedRoom._id)
                  let roomFormat = {
                    store: [store.store_name],
                    storeId: [store.store_id],
                    room: {
                      ...existedRoom,
                    },
                  }
                  privatesList.push(roomFormat)
                } else {
                  privatesList.forEach((listStore) => {
                    if (listStore.room._id === existedRoom._id) {
                      listStore.store.push(store.store_name)
                      listStore.storeId.push(store.store_id)
                    }
                  })
                }
              }
            }
          })
        })
      }
    }
    if (privatesList.length > 0) {
      setAdminPrivateChatRooms(privatesList)
    }
    checkAdminPrivateRooms(adminList, privatesList, userAdmin, otherAdmins)
  }

  const handleAdminView = (rooms, admin) => {
    let privateChats = rooms.filter((room) => room.participants.length === 2)
    let groupChats = rooms.filter((room) => room.participants.length > 2)
    let storeChats = []
    if (admin && privateChats) {
      let stores = admin.store_employees
      stores.forEach((store) => {
        let storeChat = {
          store: store.store_name,
          channels: [],
        }
        let emps = store.employees_list.filter((emp) => emp._id !== admin._id)
        emps.forEach((employee) => {
          privateChats.forEach((room) => {
            let isThere = room.participants.find(
              (participant) =>
                participant === employee._id &&
                room.participants[0] !== admin._id &&
                room.participants[1] !== admin._id
            )
            if (isThere) {
              let existed = storeChat.channels.find(
                (channel) => channel._id === room._id && room.viewers === "user"
              )
              if (!existed) {
                let first = emps.find((emp) => emp._id === room.participants[0])
                let second = emps.find(
                  (emp) => emp._id === room.participants[1]
                )

                if (first && second) {
                  let existed = storeChat.channels.find(
                    (channel) => channel._id === room._id
                  )
                  if (!existed && room.viewers === "user") {
                    storeChat.channels.push(room)
                  }
                }
              }
            }
          })
        })
        storeChats.push(storeChat)
      })
    }

    if (admin && groupChats) {
      let adminView = []
      groupChats.forEach((chat) => {
        let owned = chat.participants.find((id) => id === admin._id)
        if (!owned && chat.viewers === "user") {
          //TODO see only group chats from his stores
          adminView.push(chat)
        }
      })
      setAdminViewGroupChats(adminView)
      let roomsToTrack = props.chatRoom.channelTracker
      adminView.forEach(room => {
        roomsToTrack.push({
          id: room._id,
          messages_no: room.messages.length
        })
        props.setChannelTracker(roomsToTrack)
      })
    }
    setAdminViewChats(storeChats)
    let roomsToTrack = props.chatRoom.channelTracker
    storeChats.forEach(store => {
      store.channels.forEach(room => {
        roomsToTrack.push({
          id: room._id,
          messages_no: room.messages.length
        })
        props.setChannelTracker(roomsToTrack)
      })
    })

  }


  const checkAdminPrivateRooms = async (
    adminList,
    privatesList,
    userAdmin,
    otherAdmins
  ) => {
    let adminIds = []
    let userIds = []

    if (userAdmin && userAdmin.store_employees) {
      userAdmin.store_employees.forEach((store) => {
        let storeEmployees = store.employees_list.filter(
          (emp) => emp._id !== userAdmin._id
        )
        store.employees_list = storeEmployees

        store.employees_list.forEach((employee) => {
          if (employee.rights === "user" || employee.rights === "none") {
            let isIn = userIds.find((id) => id._id === employee._id)
            if (!isIn) {
              userIds.push(employee)
            }
          }
        })
      })
    }

    otherAdmins.forEach((admin) => {
      adminIds.push(admin)
    })

    if (adminIds.length === adminList.length) {
    } else if (adminIds.length > adminList.length) {
      adminIds.forEach((adm) => {
        let newRoom = adminList.find((admId) => admId === adm._id)
        if (!newRoom) {
          let ids = [props.app.employeeSelected._id, adm._id]
          let tracker = []

          ids.forEach((id) => {
            let track = {
              participant_id: id,
              message_no: 0,
            }
            tracker.push(track)
          })

          let newChannel = {
            merchant: props.app.user._id,
            store: [props.store.id],
            creator: ids,
            title: `${adm.name} - ${props.app.employeeSelected.name}`,
            participants: ids,
            viewers: adm.rights,
            messages: [],
            tracker: tracker,
          }
          let user = "admin"
          handleNewRooms(newChannel, privatesList, adminList, adm.rights, user)
        }
      })
    }

    if (userIds.length === privatesList.length) {
      const rooms = await CountrResources.getAllUsersChatRooms()
      handleChatSockets(rooms)
    } else if (userIds.length > privatesList.length) {

      let haveRoom = []
      privatesList.forEach((room) => {
        if (room.room.participants[0] !== props.app.employeeSelected._id) {
          haveRoom.push(room.room.participants[0])
        } else if (
          room.room.participants[1] !== props.app.employeeSelected._id
        ) {
          haveRoom.push(room.room.participants[1])
        }
      })

      userIds.forEach((emp) => {
        let exists = haveRoom.find((empId) => empId === emp._id)
        if (!exists) {
          let ids = [props.app.employeeSelected._id, emp._id]
          let tracker = []

          ids.forEach((id) => {
            let track = {
              participant_id: id,
              message_no: 0,
            }
            tracker.push(track)
          })

          let newChannel = {
            merchant: props.app.user._id,
            store: [props.store.id],
            creator: ids,
            title: `${emp.first_name} ${emp.last_name} - ${props.app.employeeSelected.name}`,
            participants: ids,
            viewers: props.app.employeeSelected.rights,
            messages: [],
            tracker: tracker,
          }
          let user = "admin"
          handleNewRooms(newChannel, privatesList, adminList, emp.rights, user)
        }
      })
    }
    if (userIds.length > privatesList.length || adminIds.length > adminList.length) {
      setMissingRooms(true)
    }
  }

  const handleNewCreatedGroup = (room) => {
    let groups = [...groupChatRooms]
    groups.push(room)
    if (groups.length > 0) {
      setGroupChatRooms(groups)
    }
    handleChatSockets(room)
  }

  const handleUpdateChannel = async () => {
    if (props.chatRoom._id && !props.chatRoom.type) {
      let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
      let room = {
        chatchannel: props.chatRoom._id,
        employeeId: props.app.employeeSelected._id,
        message_no: roomTracker.messages_no,
      }

      let updated = await CountrResources.updateTracker(room)
      if (updated && updated.participants.length > 2) {
        let group = groupChatRooms.find(id => id._id === updated._id)

        if (group) {
          let groupIndex = groupChatRooms.findIndex(id => id._id === updated._id)
          let oldGroup = groupChatRooms.filter(id => id._id !== updated._id)
          oldGroup.splice(groupIndex, 0, updated)
          setGroupChatRooms(oldGroup)
        }
      } else {
        let groupAdmin = adminChatRooms.find(id => id._id === updated._id)
        let groupPrivate = privateChatRooms.find(id => id._id === updated._id)
        let groupAdminPrivate = adminPrivateChatRooms.find(id => id._id === updated._id)

        if (groupAdmin) {
          let groupIndex = adminChatRooms.findIndex(id => id._id === updated._id)
          let oldGroup = adminChatRooms.filter(id => id._id !== updated._id)
          oldGroup.splice(groupIndex, 0, updated)
          setAdminChatRooms(oldGroup)
        }
        if (groupPrivate) {
          let groupIndex = privateChatRooms.findIndex(id => id._id === updated._id)
          let oldGroup = privateChatRooms.filter(id => id._id !== updated._id)
          oldGroup.splice(groupIndex, 0, updated)
          setPrivateChatRooms(oldGroup)
        }
        if (groupAdminPrivate) {
          let groupIndex = adminPrivateChatRooms.findIndex(id => id._id === updated._id)
          let oldGroup = adminPrivateChatRooms.filter(id => id._id !== updated._id)
          oldGroup.splice(groupIndex, 0, updated)
          setAdminPrivateChatRooms(oldGroup)
        }

      }
    }

  }

  const handleDeleteUsersChannel = (deleted) => {
    if (groupChatRooms.length > 0) {
      props.setParticipants(groupChatRooms[0])
    } else if (privateChatRooms.length > 0) {
      props.setParticipants(privateChatRooms[0])
    } else if (adminPrivateChatRooms.length > 0) {
      props.setParticipants(adminPrivateChatRooms[0])
    } else if (adminChatRooms.length > 0) {
      props.setParticipants(adminChatRooms[0])
    } else window.location.reload()

    let newGroups = groupChatRooms.filter(room => room._id !== deleted._id)
    setGroupChatRooms(newGroups)
  }

  //=======SOCKETS========>

  const handleChatSockets = async (room) => {
    let ids = []
    const countr = await AppInstances.getCountrSdk()

    if (room && room._id) {
      ids.push(room)
    } else if (room && room.length) {
      ids = room
    }

    ids.forEach(room => {
      let isParticipant = room.participants && room.participants.find(id => id === props.app.employeeSelected._id)
      if (isParticipant || room.type) {
        //Set tracker with the original messages.length
        //I use this way to counter some pagination bugs on the tracker
        let roomsToTrack = props.chatRoom.channelTracker
        roomsToTrack.push({
          id: room._id,
          messages_no: room.messages.length
        })
        props.setChannelTracker(roomsToTrack)
        //Listen for new messages
        countr.ws.on(`c${room._id}:message.created`, function (body) {
          if (body.sender !== props.app.employeeSelected._id) {
            if (body.sender.includes(`${props.app.employeeSelected.first_name}`) ||
              body.sender.includes(`${props.store.store.name}`) ||
              body.sender.includes(`Countr Support`)) {
              props.setNewSendMessage(body)
              if (body.sender.includes(`Countr Support`)) {
                setNewSupportMessage(body.supportChannel)
                setSupportCounter(`new`)
              }
            } else props.setNewIncomingMessage(body)
          } else {
            props.setNewSendMessage(body)
          }
        })
        // Listen channel read messages status
        countr.ws.on(`c${room._id}:channel.tracker`, function (body) {
          if (body.tracker && body._id) {
            props.setUpdatedTracker(body)
          }
        })
        // Listen channel typing status
        // countr.ws.on(`c${room._id}:channel.typing`, function (body) {
        //   if (body.chatchannel && body.typingId !== props.app.employeeSelected._id) {
        //     props.setChannelTyping(body)
        //   }
        // })

      }
    })
    if (!room) {
      // Listen for new channels created
      countr.ws.on(`m${props.app.user._id}:channel.created`, function (body) {
        if (body._id && body.participants) {
          props.setNewChannel(body)
        }
      })
      // Listen for channel participant updates
      countr.ws.on(`m${props.app.user._id}:channel.updated`, function (body) {
        if (body._id) {
          props.setUpdatedChannel(body)
        }
      })
      // Listen for channel deleted
      countr.ws.on(`m${props.app.user._id}:channel.deleted`, function (body) {
        if (body._id) {
          props.setDeletedChannel(body)
        }
      })
      // Listen for employee updates
      countr.ws.on(`m${props.app.user._id}:employee.updated`, function (body) {
        if (body.length > 0) {
          props.setOnlineUpdate(body)
        } else if (body._id) {
          props.setOnlineUpdate(body)
        }
      })

      // Listen for employee logout 
      countr.ws.on(`m${props.app.user._id}:employee.logout`, function (body) {
        if (body.length > 1) {
          props.setEmployeeLogout(body)
        }
      })
      setLoading(false)
    }
  }

  const handleChannelTrackerUpdate = (room) => {
    let selected
    if (room.room) {
      selected = room.room
    } else selected = room
    let filtered = props.chatRoom.channelTracker.find(id => id.id === selected._id)
    filtered.messages_no = filtered.messages_no + 1
    let updated = props.chatRoom.channelTracker.filter(id => id.id !== selected._id)
    updated.push(filtered)
    props.setChannelTracker(updated)

  }

  const handleNewMessages = async () => {
    if (props.chatRoom._id !== props.sockets.incomingMessage.chatchannel) {
      let group = groupChatRooms.find(id => id._id === props.sockets.incomingMessage.chatchannel)
      let privateRoom = privateChatRooms.find(id => id._id === props.sockets.incomingMessage.chatchannel)
      let admin = adminChatRooms.find(id => id._id === props.sockets.incomingMessage.chatchannel)
      let adminPrivate = adminPrivateChatRooms.find(id => id.room._id === props.sockets.incomingMessage.chatchannel)

      if (group) {
        handleChannelTrackerUpdate(group)
        group.messages.push(props.sockets.incomingMessage)
        let updated = groupChatRooms.filter(id => id._id !== group._id)
        updated.unshift(group)
        setGroupChatRooms(updated)

      } else if (privateRoom) {

        handleChannelTrackerUpdate(privateRoom)
        privateRoom.messages.push(props.sockets.incomingMessage)
        let updated = privateChatRooms.filter(id => id._id !== privateRoom._id)
        updated.unshift(privateRoom)
        setPrivateChatRooms(updated)

      } else if (admin) {

        handleChannelTrackerUpdate(admin)
        admin.messages.push(props.sockets.incomingMessage)
        let updated = adminChatRooms.filter(id => id._id !== admin._id)
        updated.unshift(admin)
        setAdminChatRooms(updated)

      } else if (adminPrivate) {
        handleChannelTrackerUpdate(adminPrivate)
        adminPrivate.room.messages.push(props.sockets.incomingMessage)
        let updated = adminPrivateChatRooms.filter(id => id.room._id !== adminPrivate.room._id)
        updated.unshift(adminPrivate)
        setAdminPrivateChatRooms(updated)

      }

    } else if (props.chatRoom._id === props.sockets.incomingMessage.chatchannel) {
      let roomTracker = props.chatRoom.channelTracker.find(id => id.id === props.chatRoom._id)
      let room = {
        chatchannel: props.chatRoom._id,
        employeeId: props.app.employeeSelected._id,
        message_no: Number(roomTracker.messages_no) + incomingCounter,
      }

      let updated = await CountrResources.updateTracker(room)
      if (updated.error) {
        props.showToast(updated.error)
      } else {
        setIncomingCounter(incomingCounter + 1)
      }
    }
  }

  //<=======SOCKETS========

  useEffect(() => {
    hadleAdmins()
    handleCountrSupport()
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loadedSockets && (props.app.employeeSelected.rights === 'user' || props.app.employeeSelected.rights === 'none') && props.store.admins.length) {
      handleUsersRooms()
      setLoadedSockets(true)
    } else if (!loadedSockets && props.app.employeeSelected.rights === 'admin' && props.store.admins.length) {
      handleUserIsAdmin()
      setLoadedSockets(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.admins])

  useEffect(() => {
    if (props.sockets.incomingMessage._id) {
      handleNewMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.incomingMessage])

  useEffect(() => {
    if (props.chatRoom.messages.length > 0) {
      handleUpdateChannel()
    }
    setIncomingCounter(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatRoom._id])

  useEffect(() => {
    if (props.sockets.newChannel._id) {
      let included = props.sockets.newChannel.participants.find(id => id === props.app.employeeSelected._id)
      if (props.sockets.newChannel.participants.length > 2 && included) {
        handleNewCreatedGroup(props.sockets.newChannel)

        let creator = props.sockets.newChannel.creator.find(id => id === props.app.employeeSelected._id)
        if (!creator) {
          props.showToast(`New group channel "${props.sockets.newChannel.title}" created`)
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.newChannel])

  useEffect(() => {
    if (props.sockets.updatedChannel._id) {
      if (props.chatRoom._id === props.sockets.updatedChannel._id) {
        let included = props.sockets.updatedChannel.participants.find(emp => emp._id === props.app.employeeSelected._id)
        if (included) {
          props.setParticipants(props.sockets.updatedChannel)
        } else {
          handleDeleteUsersChannel(props.sockets.updatedChannel)
        }
      } else {
        let isNewChannel = groupChatRooms.find(room => room._id === props.sockets.updatedChannel._id)

        if (!isNewChannel) {
          handleNewCreatedGroup(props.sockets.updatedChannel)
          props.showToast(`New group channel "${props.sockets.updatedChannel.title}" created`)
        } else props.showToast(`Group channel "${props.sockets.updatedChannel.title}" updated`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.updatedChannel])

  useEffect(() => {
    if (props.sockets.deletedChannel._id) {
      let newRooms = groupChatRooms.filter(room => room._id !== props.sockets.deletedChannel._id)
      setGroupChatRooms(newRooms)
      if (props.sockets.deletedChannel._id === props.chatRoom._id) {
        if (newRooms.length > 0) {
          props.setParticipants(newRooms[0])
        } else {
          handleDeleteUsersChannel(props.sockets.deletedChannel)
        }
      }
      props.showToast(`Group channel "${props.sockets.deletedChannel.title}" is deleted`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.deletedChannel])

  useEffect(() => {
    if (props.sockets.onlineUpdate.length > 1) {
      let updated = []
      props.store.allEmployees.forEach(emp => {
        if (emp._id === props.sockets.onlineUpdate) {
          emp.online = true
          updated.push(emp)
        } else updated.push(emp)
      })
      props.setAllEmployees(updated)

    } else if (props.sockets.onlineUpdate._id) {
      let updated = []
      props.store.allEmployees.forEach(emp => {
        if (emp._id === props.sockets.onlineUpdate._id) {
          emp.online = false
          updated.push(emp)
        } else updated.push(emp)
      })
      props.setAllEmployees(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.onlineUpdate])

  useEffect(() => {
    if (props.sockets.employeeLogout.length) {
      let updated = []
      props.store.allEmployees.forEach(emp => {
        if (emp._id === props.sockets.employeeLogout) {
          emp.online = false
        }
        updated.push(emp)
      })
      props.setAllEmployees(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sockets.employeeLogout])

  useEffect(() => {
    if (props.chatRoom.newSendMessage._id) {
      setIncomingCounter(incomingCounter + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatRoom.newSendMessage])

  useEffect(() => {
    if (!loading && missingRooms) {
      setLoading(true)
      localStorage.setItem(`reload_user`, props.app.employeeSelected._id)
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  return (
    <div>
      {loading ? (
        <Loader
          loading={loading}
          handleChatSockets={handleChatSockets} />

      ) : (
          <div>
            <SidePanel
              groupChatRooms={groupChatRooms}
              privateChatRooms={privateChatRooms}
              adminChatRooms={adminChatRooms}
              adminPrivateChatRooms={adminPrivateChatRooms}
              adminViewChats={adminViewChats}
              adminViewGroupChats={adminViewGroupChats}
              setAdminPrivateChatRooms={setAdminPrivateChatRooms}
              setAdminChatRooms={setAdminChatRooms}
              supportCounter={supportCounter}
              setSupportCounter={setSupportCounter}
              newSupportMessage={newSupportMessage}
            />
            <Messages />
          </div>
        )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
