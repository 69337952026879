import React from 'react'
import { connect } from 'react-redux'

import Loader from './components/Loader'
import Root from './Routes/index'
import ToastMessage from './components/ToastMessage'

import './styles/App.css'

const mapStateToProps = state => {
  return {
    app: state.app
  }
}


const App = React.memo(function App(props) {
  // const isAuth = props.app.user && Object.keys(props.app.user).length ? true : false
  return (
    <div className='app'>
      {props.app.isLoading && <Loader />}
      {/* {!props.app.currentEmbededDevice && <Menu />} */}

      {/* <Header title={process.env.REACT_APP_NAME} /> */}

      {/* {!props.app.currentEmbededDevice && isAuth && <SubHeader />} */}

      <Root />
      {props.app.showToast && <ToastMessage />}
    </div>
  )
})

export default connect(mapStateToProps)(App)
