import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import Logo from "../../assets/countr_white.png"

import CountrResources from "../../utils/CountrResources"

import { setLoading, setUser, showToast, isIframe, isDashUser } from "../../store/actions/app"
import { setStore, setEmployees, setStoreId } from "../../store/actions/store"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

// import './Messages.css'
import "../../styles/Login.css"

const mapStateToProps = (state) => {
  return {
    app: state.app,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (status) => dispatch(setLoading(status)),
    setUser: (user) => dispatch(setUser(user)),
    showToast: (msg) => dispatch(showToast(msg)),
    setStore: (store) => dispatch(setStore(store)),
    setEmployees: (employees) => dispatch(setEmployees(employees)),
    setStoreId: (storeId) => dispatch(setStoreId(storeId)),
    isIframe: iframe => dispatch(isIframe(iframe)),
    isDashUser: das => dispatch(isDashUser(das))
  }
}

const Login = React.memo((props) => {
  const [resources, setResources] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [values, setValues] = useState({
    username: "",
    password: "",
    rememberPassword: false,
  })

  const handlePasswordVisible = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]:
        name === "rememberPassword" ? event.target.checked : event.target.value,
    })

    if (name === "rememberPassword") {
      localStorage.setItem(
        "rememberPassword",
        JSON.stringify(event.target.checked)
      )
    }
  }

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleClickLogin()
    }
  }

  const handleCheckAutoLogin = () => {
    const remember = JSON.parse(localStorage.getItem("rememberPassword"))

    if (remember !== null && remember) {
      props.setLoading(true)
      let auto = new CountrResources()
      auto.autoLogin().then(
        (user) => {
          if (user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID) {
            handleCountrSupport(user)
          }
          else {
            successLogin(user)
          }
        },
        (error) => {
          errorLogin(error)
        }
      )
    }
  }

  const handleClickLogin = () => {
    if (!props.app.isLoading) {
      props.setLoading(true)
      try {
        login().then((user) => {
          if (user._id === process.env.REACT_APP_COUNTR_SUPPORT_ID) {
            handleCountrSupport(user)
          }
          else {
            successLogin(user)
          }
        })
      } catch (error) {
        errorLogin(error)
      }
    }
  }

  const login = () => {
    const username = values.username
    const password = values.password

    return new Promise(async (resolve) => {
      try {
        const user = await resources.login(username, password)
        resolve(user)
      } catch (error) {
        errorLogin(error)
      }
    })
  }

  const handleCountrSupport = async countr => {
    const employees = await CountrResources.getEmployees()
    let list = []

    props.setUser(countr)
    let resource = new CountrResources()
    const stores = await resource.getResource("stores")
    if (stores && stores.length) {
      let store = stores.find((store) => store._id === process.env.REACT_APP_COUNTR_SUPPORT_ID)

      store.employees.forEach((employee) => {
        let selected = employees.find((emp) => emp._id === employee)
        list.push(selected)
      })
      props.setEmployees(list)

      props.history.push("/employee")

    }

  }

  const successLogin = async (user) => {
    let list = []
    const employees = await CountrResources.getEmployees()

    const selectedStore = localStorage.getItem("CountrChat:SelectedStore")

    props.setUser(user)

    if (selectedStore) {
      try {
        let resource = new CountrResources()
        const stores = await resource.getResource("stores")
        if (stores && stores.length) {
          let store = stores.find((store) => store._id === selectedStore)

          props.setStore(store)

          props.setStoreId(store._id)

          store.employees.forEach((employee) => {
            let selected = employees.find((emp) => emp._id === employee)
            list.push(selected)
          })
          props.setEmployees(list)

          props.history.push(store ? "/employee" : "/store")
        } else {
          props.history.push("/store")
        }
      } catch (error) {
        props.history.push("/store")
      }
    } else {
      props.history.push("/store")
    }

    props.setLoading(false)
  }

  const errorLogin = (error) => {
    props.setLoading(false)
    const msg = error.message ? error.message : JSON.stringify(error)
    props.showToast(JSON.stringify(msg))
  }

  const handleIframeLogin = () => {
    let loginListener = window.location.search.substring(1)
    if (loginListener) {
      props.setLoading(true)
      props.isIframe(true)

      let pars = loginListener.split("&")
      pars.forEach(par => {
        if (par.split("=")[0] === 'token') {
          localStorage.setItem('access_token', par.split("=")[1])
        } else if (par.split("=")[0] === 'store') {
          localStorage.setItem("CountrChat:SelectedStore", par.split("=")[1])
          props.setStoreId(par.split("=")[1])
        } else if (par.split("=")[0] === 'employee') {
          if (par.split("=")[1] !== 'undefined') {
            localStorage.setItem('ChatKeepLog:', par.split("=")[1])
          }
        } else if (par.split("=")[0] === 'dashboard') {
          localStorage.setItem('access_token', par.split("=")[1])
          props.isDashUser(true)
        }
      })

      let auto = new CountrResources()
      auto.autoLogin().then(
        (user) => {
          successLogin(user)
        },
        (error) => {
          errorLogin(error)
        }
      )
    }

  }

  useEffect(() => {
    setResources(new CountrResources())
    handleCheckAutoLogin()
    handleIframeLogin()
    return () => {
      setResources(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid id="app-login" container className="flex-center" style={{ backgroundColor: '#D3D3D3', height: '100vh' }}>
      <Paper elevation={20} className="login-form-paper">
        <Grid item xs={12}>
          <div className="logo-div">
            <Typography>
              <img className="main-header-logo-login" src={Logo} alt="logo" />
            </Typography>
            <div className="main-header-logo-text-login">Chat</div>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2vh" }}>
          <TextField
            label="Username"
            className="login-input"
            variant="outlined"
            type="email"
            value={values.username}
            onChange={handleChange("username")}
            onKeyPress={(event) => handleEnterPress(event)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoComplete="true"
            label="Password"
            className="login-input"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            onKeyPress={(event) => handleEnterPress(event)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => handlePasswordVisible()}
                  >
                    {showPassword ? (
                      <Icon>
                        <Visibility />
                      </Icon>
                    ) : (
                        <Icon>
                          <VisibilityOff />
                        </Icon>
                      )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            label="Login"
            className="login-button"
            variant="contained"
            onClick={handleClickLogin}
          >
            Login
          </Button>
        </Grid>

        <Grid item xs={12} className="login-checkbox">
          <FormGroup row className="login-form-control-row">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={values.rememberPassword}
                  onChange={handleChange("rememberPassword")}
                  value="rememberPassword"
                />
              }
              label="Remember password"
            />
          </FormGroup>
        </Grid>
      </Paper>
    </Grid>
  )
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
