import React from 'react'

import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import SendMessage from './Main/Messages/SendMessage'

const Skeletons = React.memo(function Skeletons(props) {
    return (
        <Grid container>
            <Grid container style={{ marginTop: '2vh' }}>
                <Grid item xs={2}>
                    <Skeleton animation="wave" variant="rect" width={60} height={60} style={{ backgroundColor: '#ECECEC', marginLeft: '3vw', borderRadius: '15%' }} />
                </Grid>
                <Grid item xs={10}>
                    <Skeleton animation="wave" style={{ marginRight: '5vw', backgroundColor: '#ECECEC' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                </Grid>
            </Grid>

            <Grid container style={{ marginTop: '2vh' }}>
                <Grid item xs={2}>
                    <Skeleton animation="wave" variant="rect" width={60} height={60} style={{ backgroundColor: '#ECECEC', marginLeft: '3vw' , borderRadius: '15%' }} />
                </Grid>
                <Grid item xs={10}>
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                </Grid>
            </Grid>

            <Grid container style={{ marginTop: '2vh' }}>
                <Grid item xs={2}>
                    <Skeleton animation="wave" variant="rect" width={60} height={60} style={{ backgroundColor: '#ECECEC', marginLeft: '3vw' , borderRadius: '15%' }} />
                </Grid>
                <Grid item xs={10}>
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                </Grid>
            </Grid>

            <Grid container style={{ marginTop: '2vh' }}>
                <Grid item xs={2}>
                    <Skeleton animation="wave" variant="rect" width={60} height={60} style={{ backgroundColor: '#ECECEC', marginLeft: '3vw' , borderRadius: '15%' }} />
                </Grid>
                <Grid item xs={10}>
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                    <Skeleton animation="wave" style={{ marginRight: '5vw' }} />
                </Grid>
            </Grid>

            <Grid container style={{ marginTop: '5.6vh' }}>
                <Grid item xs={12}>
                    <SendMessage skeletons={true}/>
                </Grid>
            </Grid>

        </Grid>
    )
})

export default Skeletons
