import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MessageRoomHeader from './MessageRoomHeader'
import Message from './Message'
import Skeletons from '../../Skeleton'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import '../../../styles/Messages.css'

const mapStateToProps = state => {
    return {
        app: state.app,
        chatRoom: state.chatRoom
    }
}


const Messages = React.memo(props => {
    const [mobileView, setMobileView] = useState(false)

    const handleAdminView = () => {
        if (props.app.isDasUser) {
            return true
        } else if (props.chatRoom.participants) {
            let user = props.app.employeeSelected._id
            let isView = props.chatRoom.participants.find(id => id === user)

            if (isView) {
                return true
            } else return false
        } else return true
    }

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        if (vw < 650) {
            setMobileView(true)
        }
    }, [])


    return (
        <div className={mobileView ? 'main-messages-div-mobile' : "main-messages-div"}>
            <Paper className="main-messages-paper countr-fonts">
                <Grid container>
                    <Grid item xs={12}>
                        <MessageRoomHeader />
                    </Grid>
                    {props.chatRoom.roomLoading ? (
                        <Grid container>
                            <Skeletons isAdminView={handleAdminView()} />
                        </Grid>

                    ) : (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Message />
                                </Grid>
                            </Grid>
                        )}

                </Grid>
            </Paper>

        </div >
    )
})

export default connect(mapStateToProps)(Messages)
