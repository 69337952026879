import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import CountrResources from "../../../utils/CountrResources"

import NewGroupChat from "./NewGroupChat"
import ChangeAvatarModal from "./ChangeAvatarModal"
import PanelMenu from "./PanelMenu"
import Logo from "../../../assets/countr_white.png"

import { makeStyles } from "@material-ui/core/styles"

import { setEmployee } from "../../../store/actions/app"
import { setParticipants, setRoomLoading, setChannelTracker } from "../../../store/actions/chatRoom"

import Brightness1Icon from "@material-ui/icons/Brightness1"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import GroupIcon from "@material-ui/icons/Group"
import EmailIcon from "@material-ui/icons/Email"
import ControlPointIcon from "@material-ui/icons/ControlPoint"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import StreetviewIcon from "@material-ui/icons/Streetview"
import MenuIcon from "@material-ui/icons/Menu"

import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Popover from "@material-ui/core/Popover"
import Button from "@material-ui/core/Button"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import Badge from "@material-ui/core/Badge"
import Collapse from "@material-ui/core/Collapse"

import "../../../styles/SidePanel.css"

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#1d212757",
      outline: "1px solid slategrey",
    },
  },

  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#000",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1d2127",
    color: "#fff",
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const mapStateToProps = (state) => {
  return {
    app: state.app,
    store: state.store,
    chatRoom: state.chatRoom,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setParticipants: (user) => dispatch(setParticipants(user)),
    setEmployee: (employee) => dispatch(setEmployee(employee)),
    setRoomLoading: (loading) => dispatch(setRoomLoading(loading)),
    setChannelTracker: (tracker) => dispatch(setChannelTracker(tracker))
  }
}

const SidePanel = React.memo((props) => {
  const classes = useStyles()
  const [employeeOnUse, setEmployeeOnUse] = useState({})
  const [openChatMenu, setOpenChatMenu] = useState(false)
  const [popOvertarget, setPopOverTarget] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [expandedinside, setExpandedInside] = useState(false)
  const [openNewGroupChat, setOpenNewGroupChat] = useState(false)
  const [mobileView, setMobileView] = useState(false)
  const [viewWidth, setViewWidth] = useState(0)
  const [openAvatarModal, setOpenAvatarModal] = useState(false)
  const [formatedRooms, setFormatedRooms] = useState(false)
  const [storeSupportMsgs, setStoreSupportmsgs] = useState(null)
  const [privateSupportMsgs, setPrivateSupportmsgs] = useState(null)

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === 'panel2' && props.app.employeeSelected.rights === 'admin') {
      let notFormated = props.adminPrivateChatRooms.find(room => !room.room)
      if (notFormated) {
        handleRoomsFormat()
      } else {
        setFormatedRooms(true)
        setExpanded(isExpanded ? panel : false)
      }
    } else if (panel === 'panel3') {
      props.setSupportCounter(null)
      setExpanded(isExpanded ? panel : false)
    } else {
      setExpanded(isExpanded ? panel : false)
    }
  }

  const handleChangeInside = (panel) => (event, isExpanded) => {
    setExpandedInside(isExpanded ? panel : false)
  }

  const handleNewGroupChat = () => {
    if (!openNewGroupChat) {
      setOpenNewGroupChat(true)
    } else {
      setOpenNewGroupChat(false)
    }
  }

  const handleOpenChatMenu = (target) => {
    if (!openChatMenu) {
      setOpenChatMenu(true)
      setPopOverTarget(target)
    } else setOpenChatMenu(false)
  }

  const handleSupportChannel = async (channel) => {
    props.setRoomLoading(true)

    let room = await CountrResources.getSupportChatRoom(channel._id)
    props.setParticipants(room)

    if (room) {
      if (room.type === 'Store') {
        localStorage.setItem('StoreSupportMsgs:', room.messages.length)
        handleStoreSupportIncomingUnread()
      } else if (room.type === 'Private') {
        handlePrivateSupportIncomingUnread()
        localStorage.setItem('PrivateSupportMsgs:', room.messages.length)
      }
      props.setRoomLoading(false)
    } else {
      console.log('error')
    }
    props.setRoomLoading(false)

  }

  const handleChatChange = async (channel, isAdmin) => {
    let selectedRoom
    if (channel.room) {
      selectedRoom = channel.room
    } else selectedRoom = channel
    if (viewWidth < 650) {
      setTimeout(() => {
        setMobileView(mobileView ? false : true)
      }, 500)
    }
    props.setRoomLoading(true)
    let room = await CountrResources.getChatRoom(selectedRoom._id, isAdmin)
    props.setParticipants(room)

    if (room) {
      if (props.app.employeeSelected.rights === 'admin') {
        let updatedRooms = []
        props.adminPrivateChatRooms.forEach(adminRoom => {
          if (adminRoom.room._id === room._id) {
            room.tracker.forEach(id => {
              if (id.participant_id === props.app.employeeSelected._id) {
                let selectedTracker = props.chatRoom.channelTracker.find(id => id.id === room._id)
                if (selectedTracker) {
                  id.message_no = selectedTracker.messages_no

                }
                // console.log("handleChatChange -> props.chatRoom", props.chatRoom.channelTracker)
              }
            })
            adminRoom.room = room
          }
          updatedRooms.push(adminRoom)
        })
        props.setAdminPrivateChatRooms(updatedRooms)
      }
      props.setRoomLoading(false)
    } else {
      console.log('error')
    }
  }

  const handleRoomTitle = (title) => {
    let newTitle = ""
    let thisTitle = title.split("-")
    thisTitle.forEach((name) => {
      if (name.trim() !== props.app.employeeSelected.name.trim()) {
        newTitle = name
      }
    })
    return newTitle
  }

  const handleAdminsPrivateStore = (stores) => {
    let combined = stores.join("-")
    return combined
  }



  const handleCategoryUnread = (rooms) => {
    let unread = 0
    let unreadMessages = 0
    if (rooms.length > 0) {
      rooms.forEach((room) => {
        let formatedRoom = {}
        if (room.room) {
          formatedRoom = room.room
        } else formatedRoom = room

        formatedRoom.tracker.forEach((id) => {
          if (id.participant_id === props.app.employeeSelected._id) {
            let formatedRoom
            if (room.room) {
              formatedRoom = room.room
            } else formatedRoom = room
            let channelTracker = props.chatRoom.channelTracker.find(id => id.id === formatedRoom._id)

            if (channelTracker) {
              let channelTrackerMessages = channelTracker.messages_no > 0 ? channelTracker.messages_no : 0
              let idMessages = id.message_no > 0 ? id.message_no : 0
              unread = channelTrackerMessages - idMessages + unread
              if (unread > 0) {
                unreadMessages = unread
              }
            }
          }
        })
      })
    }
    return unreadMessages
  }
  const handleAdminViewRoom = (room) => {
    return (
      <Accordion
        key={room.store}
        className="panel-main-menu"
        expanded={expandedinside === `${room.store}`}
        onChange={handleChangeInside(`${room.store}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header">
          <Grid container>
            <Grid item xs={12}>
              <Typography className="countr-fonts">{room.store}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {room.channels.map((channel) => (
              <ListItem

                style={handleIsActive(room._id)}
                button
                key={channel._id}
                onClick={() => handleChatChange(channel, true)}>
                <Typography className="countr-sub-fonts">{channel.title}</Typography>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  }

  const handleIsActive = (id) => {
    let active = { color: "#ffffff" }
    if (props.chatRoom._id && props.chatRoom._id === id) {
      active = { color: "#00FF00" }
    }

    return active
  }

  const handleUnreadMessages = (room) => {
    let unread = null
    room.tracker.forEach((id) => {
      if (id.participant_id === props.app.employeeSelected._id) {
        let channelTracker = props.chatRoom.channelTracker.find(id => id.id === room._id)
        if (channelTracker) {
          unread = channelTracker.messages_no - Number(id.message_no)
        }
      }
    })
    return unread > 0 ? unread : null
  }

  const handleIsOnline = (ids) => {

    let participantId = ids.filter(
      (id) => id !== props.app.employeeSelected._id
    )
    let employee = props.store.allEmployees.find(
      (emp) => emp._id === participantId[0]
    )
    if (employee && employee.online) {
      return { fontSize: "12px", marginRight: "15px", color: "#44b700" }
    } else return { fontSize: "12px", marginRight: "15px", color: "red" }
  }

  const handleChangeAvatarModal = () => {
    if (openAvatarModal) {
      setOpenAvatarModal(false)
    } else setOpenAvatarModal(true)

  }

  const handleRoomsFormat = async () => {
    // window.location.reload()
    let rooms = await CountrResources.getAllUsersChatRooms()
    let privateRooms = rooms.filter((room) => room.participants.length === 2)
    let admins = props.store.admins
    let userAdmin = admins.find(
      (admin) => admin._id === props.app.employeeSelected._id
    )
    let otherAdmins = admins.filter(
      (admin) => admin._id !== props.app.employeeSelected._id
    )
    let createdIds = []
    let adminList = []
    let privatesList = []

    //set admin rooms
    privateRooms.forEach((room) => {
      otherAdmins.forEach((other) => {
        if (
          (room.participants[0] === other._id &&
            room.participants[1] === userAdmin._id) ||
          (room.participants[0] === userAdmin._id &&
            room.participants[1] === other._id)
        ) {
          room = {
            ...room,
            store_name: other.store,
          }
          adminList.push(room)
        }
      })
    })
    if (adminList.length > 0) {
      props.setAdminChatRooms(adminList)
    }

    //set private rooms and check if he is register in many stores
    if (userAdmin.store_employees.length > 0) {
      userAdmin.store_employees.forEach((store) => {
        store.employees_list.forEach((emp) => {
          if (emp.rights === "user" || emp.rights === "none") {
            let existedRoom = privateRooms.find(
              (room) =>
                (room.participants[0] === emp._id &&
                  room.participants[1] === userAdmin._id) ||
                (room.participants[0] === userAdmin._id &&
                  room.participants[1] === emp._id)
            )
            if (existedRoom) {
              let isManyStore = createdIds.find(
                (id) => id === existedRoom._id
              )
              if (!isManyStore) {
                createdIds.push(existedRoom._id)
                let roomFormat = {
                  store: [store.store_name],
                  storeId: [store.store_id],
                  room: {
                    ...existedRoom,
                  },
                }
                privatesList.push(roomFormat)
              } else {
                privatesList.forEach((listStore) => {
                  if (listStore.room._id === existedRoom._id) {
                    listStore.store.push(store.store_name)
                    listStore.storeId.push(store.store_id)
                  }
                })
              }
            }
          }
        })
      })
    }
    if (privatesList.length > 0) {
      props.setAdminPrivateChatRooms(privatesList)
    }
    setFormatedRooms(true)
  }

  const handlePrivateSupportIncomingUnread = () => {
    if (props.chatRoom.privateSupportChannel.messages) {
      let count = props.chatRoom.privateSupportChannel.messages.length
      let prevCount = localStorage.getItem('PrivateSupportMsgs:')
      if (prevCount) {
        setPrivateSupportmsgs(count - prevCount <= 0 ? null : count - prevCount)
      } else setStoreSupportmsgs(null)
    }
  }

  const handleStoreSupportIncomingUnread = () => {
    if (props.chatRoom.storeSupportChannel.messages) {
      let count = props.chatRoom.storeSupportChannel.messages.length
      let prevCount = localStorage.getItem('StoreSupportMsgs:')
      if (prevCount) {
        setStoreSupportmsgs(count - prevCount <= 0 ? null : count - prevCount)
      } else setStoreSupportmsgs(null)
    }
  }


  useEffect(() => {
    setEmployeeOnUse(props.app.employeeSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store.admins])

  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    setViewWidth(vw)
    if (vw < 650) {
      setMobileView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.adminPrivateChatRooms.length > 0 && !props.adminPrivateChatRooms[0].room) {
      handleRoomsFormat()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminPrivateChatRooms])

  useEffect(() => {
    handlePrivateSupportIncomingUnread()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatRoom.privateSupportChannel.messages])

  useEffect(() => {
    handleStoreSupportIncomingUnread()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatRoom.storeSupportChannel.messages])

  useEffect(() => {
    if (props.newSupportMessage === props.chatRoom.privateSupportChannel._id) {
      if (props.chatRoom._id === props.chatRoom.privateSupportChannel._id) {
        localStorage.setItem('PrivateSupportMsgs:', props.chatRoom.messages.length + 1)
      } else setPrivateSupportmsgs(`new`)
    } else if (props.newSupportMessage === props.chatRoom.storeSupportChannel._id) {
      if (props.chatRoom._id === props.chatRoom.storeSupportChannel._id) {
        localStorage.setItem('StoreSupportMsgs:', props.chatRoom.messages.length + 1)
      } else setStoreSupportmsgs(`new`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newSupportMessage])

  return (
    <div className="side-panel">
      {mobileView ? (
        <Collapse in={mobileView} timeout={2000}>
          <Drawer
            className="mobile-view-button"
            variant="persistent"
            anchor="left"
            open={mobileView}>
            <MenuIcon
              style={{ fontSize: 40 }}
              button="true"
              onClick={() => setMobileView(mobileView ? false : true)}
            />
          </Drawer>
        </Collapse>
      ) : (
          <div className={classes.root}>
            <CssBaseline />
            <Collapse in={!mobileView}>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={!mobileView}
                classes={{
                  paper: classes.drawerPaper,
                }}>
                {viewWidth < 650 ? (
                  <img
                    className="main-menu-logo-login"
                    src={Logo}
                    alt="logo"
                    button={mobileView ? "true" : "false"}
                    onClick={() => setMobileView(mobileView ? false : true)}
                  />
                ) : (
                    <div>
                      {!props.app.isIframe && (
                        <img className="main-menu-logo-login" src={Logo} alt="logo" />
                      )}
                    </div>
                  )}
                <div
                  className={classes.drawerHeader}
                  style={{ marginRight: "5vw", marginTop: "2vh" }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <span>
                        <IconButton
                          onClick={(event) =>
                            handleOpenChatMenu(event.currentTarget)
                          }>
                          <Avatar
                            className="avatar-user"
                            variant="square"
                            // style={{ backgroundColor: '#e8fbe8' }}
                            src={props.app.employeeSelected.contactRawSchema ?
                              props.app.employeeSelected.contactRawSchema.image :
                              `https://avatars.dicebear.com/v2/jdenticon/${props.app.employeeSelected.first_name}${props.app.employeeSelected.last_name}.svg`
                            }
                          />
                        </IconButton>
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="chat-owner-name">{employeeOnUse.name}</div>
                    </Grid>
                  </Grid>
                </div>

                <Accordion
                  style={{ marginTop: "5vh" }}
                  className="panel-main-menu"
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Grid container>
                      <Grid item xs={2}>
                        <ContactSupportIcon className="menu-icons" />
                      </Grid>
                      <Grid item xs={10}>
                        <Badge
                          color="error"
                          badgeContent={storeSupportMsgs || privateSupportMsgs ? 'new' : null}
                        >
                          <Typography className="countr-fonts">Countr Support</Typography>
                        </Badge>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <List>
                          <ListItem
                            style={handleIsActive(props.chatRoom.storeSupportChannel._id)}
                            className="group-chat-btns"
                            button={true}
                            onClick={() => handleSupportChannel(props.chatRoom.storeSupportChannel)}
                          >
                            <Badge
                              color="error"
                              badgeContent={storeSupportMsgs}
                            >
                              <ListItemText>@ Store Channel</ListItemText>
                            </Badge>
                          </ListItem>

                          <ListItem
                            style={handleIsActive(props.chatRoom.privateSupportChannel._id)}
                            className="group-chat-btns"
                            button={true}
                            onClick={() => handleSupportChannel(props.chatRoom.privateSupportChannel)}
                          >
                            <Badge
                              color="error"
                              badgeContent={privateSupportMsgs}
                            >
                              <ListItemText>@ Private Message</ListItemText>
                            </Badge>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Divider />

                <Accordion
                  className="panel-main-menu"
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Grid container>
                      <Grid item xs={2}>
                        <AccountCircleIcon className="menu-icons" />
                      </Grid>
                      <Grid item xs={10}>
                        <Badge
                          color="error"
                          badgeContent={handleCategoryUnread(props.adminChatRooms)}>
                          <Typography className="countr-fonts">Admin Chat</Typography>
                        </Badge>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <List>
                          {props.adminChatRooms.length > 0 ? props.adminChatRooms.map((room) => (
                            <ListItem
                              style={handleIsActive(room._id)}
                              className="admin-rooms-list"
                              button
                              key={room._id}
                              onClick={() => handleChatChange(room)}>
                              <Brightness1Icon
                                style={handleIsOnline(room.participants)}
                              />
                              <Badge
                                color="error"
                                badgeContent={handleUnreadMessages(room)}>
                                <ListItemText
                                  className="chat-room-lsit countr-sub-fonts"
                                  primary={`@ ${handleRoomTitle(room.title)}`}
                                  secondary={
                                    props.app.employeeSelected.rights === "admin"
                                      ? `( ${room.store_name} )`
                                      : ""
                                  }
                                />
                              </Badge>
                            </ListItem>
                          )) : (
                              <ListItem>
                                <ListItemText
                                  primary='No admins in this store'
                                />
                              </ListItem>
                            )}
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Divider />

                <Accordion
                  className="panel-main-menu"
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Grid container>
                      <Grid item xs={2}>
                        <GroupIcon className="menu-icons" />
                      </Grid>
                      <Grid item xs={10}>
                        <Badge
                          color="error"
                          badgeContent={handleCategoryUnread(props.groupChatRooms)}>
                          <Typography className="countr-fonts">Group Chats</Typography>
                        </Badge>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <Button
                          className="new-group-btn"
                          variant="outlined"
                          onClick={handleNewGroupChat}>
                          <Grid container style={{ marginTop: "0.5vh", marginRight: '7px' }}>
                            <Grid item xs={11} className="countr-fonts">
                              New group chat
                          </Grid>
                            <Grid item xs={1}>
                              <ControlPointIcon fontSize="small" />
                            </Grid>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <List>
                          {props.groupChatRooms.map((room) => (
                            <ListItem
                              className="countr-sub-fonts-group"
                              style={handleIsActive(room._id)}
                              value={room._id}
                              key={room._id}
                              button={true}
                              onClick={() => handleChatChange(room)}>
                              <Badge
                                color="error"
                                badgeContent={handleUnreadMessages(room)}>
                                <ListItemText># {room.title}</ListItemText>
                              </Badge>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Divider />

                {(props.app.employeeSelected.rights === "user" || props.app.employeeSelected.rights === "none") && (
                  <Accordion
                    className="panel-main-menu"
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header">
                      <Grid container>
                        <Grid item xs={2}>
                          <EmailIcon className="menu-icons" />
                        </Grid>
                        <Grid item xs={10}>
                          <Badge
                            color="error"
                            badgeContent={handleCategoryUnread(props.privateChatRooms)}>
                            <Typography className="countr-fonts">Private Messages</Typography>
                          </Badge>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {props.privateChatRooms.map((room) => (
                          <ListItem
                            className="countr-sub-fonts"
                            style={handleIsActive(room._id)}
                            button
                            key={room._id}
                            onClick={() => handleChatChange(room)}>
                            <Brightness1Icon
                              style={handleIsOnline(room.participants)}
                            />
                            <Badge
                              color="error"
                              badgeContent={handleUnreadMessages(room)}>
                              <ListItemText>
                                {handleRoomTitle(room.title)}
                              </ListItemText>
                            </Badge>
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {props.app.employeeSelected.rights === "admin" && (
                  <Accordion
                    className="panel-main-menu"
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header">
                      <Grid container>
                        <Grid item xs={2}>
                          <EmailIcon className="menu-icons" />
                        </Grid>
                        <Grid item xs={10}>
                          <Badge
                            color="error"
                            badgeContent={handleCategoryUnread(
                              props.adminPrivateChatRooms
                            )}>
                            <Typography className="countr-fonts">Private Messages</Typography>
                          </Badge>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {props.adminPrivateChatRooms.length > 0 && formatedRooms && props.adminPrivateChatRooms.map((room) => (
                          <ListItem
                            style={handleIsActive(room._id)}
                            className="admin-mode-employee-stores countr-sub-fonts"
                            button
                            key={room.room._id}
                            onClick={() => handleChatChange(room)}>
                            <Brightness1Icon
                              style={handleIsOnline(room.room.participants)}
                            />
                            <Badge
                              color="error"
                              badgeContent={handleUnreadMessages(room.room)}>
                              <ListItemText
                                primary={handleRoomTitle(room.room.title)}
                                secondary={`( ${handleAdminsPrivateStore(
                                  room.store
                                )} )`}
                              />
                            </Badge>
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Divider />

                {props.app.employeeSelected.rights === "admin" && (
                  <Accordion
                    className="panel-main-menu"
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header">
                      <Grid container>
                        <Grid item xs={2}>
                          <StreetviewIcon className="menu-icons" />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography className="countr-fonts">Admin View</Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <Accordion
                          key={"group-channels"}
                          className="panel-main-menu"
                          expanded={expandedinside === `group-channels`}
                          onChange={handleChangeInside(`group-channels`)}>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "#ffffff" }} />
                            }
                            aria-controls="panel2bh-content"
                            id="panel2bh-header">
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography className="countr-fonts"># Group Chats</Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {props.adminViewGroupChats.map((channel) => (
                                <ListItem
                                  className="countr-sub-fonts"
                                  style={handleIsActive(channel._id)}
                                  button
                                  key={channel._id}
                                  onClick={() => handleChatChange(channel, true)}>
                                  <Typography>
                                    {channel.title}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>

                        {props.adminViewChats.map((room) => handleAdminViewRoom(room))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Drawer>
            </Collapse>
            <Popover
              className="chat-in-menu"
              anchorEl={popOvertarget}
              open={openChatMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <PanelMenu
                handleOpenChatMenu={handleOpenChatMenu}
                handleChangeAvatarModal={handleChangeAvatarModal} />
            </Popover>
          </div>
        )}
      {openNewGroupChat && (
        <NewGroupChat
          open={openNewGroupChat}
          handleClose={handleNewGroupChat}
        />
      )}
      {openAvatarModal && (
        <ChangeAvatarModal
          open={openAvatarModal}
          handleClose={handleChangeAvatarModal}
        />
      )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)

