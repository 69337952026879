import { createStore, combineReducers } from 'redux'
import appReducer from '../store/redusers/app'
import storeReducer from '../store/redusers/store'
import deviceReducer from '../store/redusers/device'
import chatRoomReducer from '../store/redusers/chatRoom'
import socketReducer from '../store/redusers/sockets'

const rootReducer = combineReducers({
  app: appReducer,
  store: storeReducer,
  device: deviceReducer,
  chatRoom: chatRoomReducer,
  sockets: socketReducer,
})

const configureStore = () => {
  return createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

export default configureStore
